.algolia-container{
  width: 100%;
  height: 48px;
  position: relative;
  .ais-SearchBox{
    form{
      input[type="search"]::-webkit-search-decoration,
      input[type="search"]::-webkit-search-cancel-button,
      input[type="search"]::-webkit-search-results-button,
      input[type="search"]::-webkit-search-results-decoration { display: none; }
      input::placeholder{
        font-size: 16px;
        color: white;
        opacity: 0.6;
        font-family: 'Satoshi';
      }
      input{
        width: 100%;
        height: 48px;
        border: none;
        border-radius: 100px;
        box-sizing: border-box;
        padding: 10px 20px;
        border: 2px solid rgba(255, 255, 255, 0.03);
        background: rgba(21, 24, 30, 0.60);
        backdrop-filter: blur(100px);
        color: white;
        font-size: 16px;
        font-family: 'Satoshi';
      }
      .buttons{
        height: 48px;
        width: 110px;
        position: absolute;
        right: 0;
        top: 0;
        .ais-SearchBox-clear:hover{
          background-color: #ff4259;
          svg{
            opacity: 0.8;
          }
          p{
            opacity: 1;
          }
        }
        .ais-SearchBox-clear{
          opacity: 1;
          transition: 0.3s;
          position: absolute;
          background-color: rgba(255, 255, 255, 0.05);
          border: none;
          right: 8px;
          top: 8px;
          padding: 4px 10px;
          border-radius: 7px 16px 16px 7px;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 5px;
          cursor: pointer;
          p{
            transition: 0.3s;
            font-size: 14px;
            font-family: 'Satoshi';
            color: white;
            font-weight: 500;
            opacity: 0.5;
            margin: 0;
            margin-right: 4px;
          }
          svg{
            transition: 0.3s;
            width: 18px;
            color: white;
            opacity: 0.25;
          }
        }
      }
    }
  }
  .hits-scrollbox{
    padding-top: 20px;
    width: 100%;
    height: calc(100vh - 46px - 20px);
    overflow-y: scroll;
    position: relative;
    top: -20px;
    z-index: -1;
  }
  .ais-Hits{
    ol{
      list-style-type: none;
      margin: 0;
      padding: 0;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
      row-gap: 20px;
      margin-top: 20px;
      margin-bottom: 20px;
      li{
        list-style-type: none;
        margin: 0;  
        padding: 0;
        height: fit-content;
      }
    }
  }
}

.hit-container{
  background-color:rgba(0, 0, 0, 0.2);
  border: 2px solid rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(100px);
  display: flex;
  align-items: center;
  justify-content: center;
  // margin: 20px 0;
  padding: 16px 20px;
  border-radius: 20px;
  .hit-title{
    h1{
      margin: 10px 5px;
      font-size: 22px;
      opacity: 0.9;
      height: 59px;
    }
    p{
      margin: 0px 5px;
      font-size: 18px;
      font-weight: 500;
      display: -webkit-box;
      opacity: 0.8;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      margin-bottom: 10px;
    }
    .tags{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      .type{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        width: fit-content;
        padding: 10px 16px;
        border-radius: 10px;
        svg{
          width: 20px;
        }
        p{
          margin: 0;
          font-size: 18px;
        }
      }
      .type{
        background-color:rgba(0, 0, 0, 0.2);
        border: 2px solid rgba(255, 255, 255, 0.05);
        backdrop-filter: blur(100px);
        p{
          opacity: 0.5;
          font-weight: 500;
        }
        svg{
          opacity: 0.9;
        }
      }
    }
    .go:hover{
      background: rgba(0, 133, 255, 0.8);
    }
    .go{
      display: flex;
      align-items: center;
      justify-content: center;
      width: fit-content;
      padding: 10px 16px;
      border-radius: 10px;
      border: none;
      background: rgb(0, 133, 255);
      cursor: pointer;
      transition: 0.3s;
      p{
        font-family: 'Satoshi';
        font-weight: 500;
        color: white;
        margin: 0;
      }
      svg{
        width: 20px;
      }
    }
  }
}