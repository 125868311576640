.row {
  z-index: 63;
  display: flex;
  align-items: center;
  column-gap: 10px;
  .people {
    display: flex;

    .circle-avatar {
      border-radius: 50%;
      width: 40px;
      height: 40px;
      position: relative;
      z-index: 3;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      object-fit: cover;
    }
    .svg1 {
      z-index: 2;
      margin-left: -4px;
    }
    .svg2 {
      z-index: 1;
      margin-left: -5px;
    }
    svg {
      width: 25px;
      height: 40px;
      object-fit: cover;
      background-size: cover;
      background-position: left left;
      background-repeat: no-repeat;
      defs{
        pattern{
          // height: 50px;
          // width: 100px;
          image {
            object-fit: cover;
            background-size: cover;
            // background-repeat: no-repeat;
            background-position: left left !important;
            background-repeat: no-repeat;
            // width: 65px;
            // height: 98px;
            // height: 105%;
            width: 175%;
            // margin-bottom: 30px;
          }
        }
      }

      path{
        object-fit: cover;
        background-size: cover;
        background-position: left left;
        background-repeat: no-repeat;
      }

    }
  }
  .number {
    background-color: #0085ff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 13px;
    column-gap: 1px;
    border-radius: 81px;
    padding: 8px 14px;
    color: white;
    font-weight: 800;
    span{
      margin: 0;
      line-height: 100%;
    }
  }
}


  .flex-items{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 77;
    .row {
      z-index: 23;
      display: flex;
      align-items: center;
      column-gap: 10px;
      .people {
        display: flex;
  
        .circle-avatar {
          border-radius: 50%;
          width: 40px;
          height: 40px;
          position: relative;
          z-index: 3;
        }
        .svg1 {
          z-index: 2;
          margin-left: -4px;
        }
        .svg2 {
          z-index: 1;
          margin-left: -5px;
        }
        svg {
          width: 25px;
          height: 40px;
          image {
            background-size: cover;
            background-position: left top;
          }
        }
      }
      .number {
        background-color: #0085ff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 20px;
        font-size: 13px;
        column-gap: 1px;
        border-radius: 81px;
        padding: 2px 9px 2px 7px;
        color: white;
        font-weight: 800;
        span {
          margin-left: 2px;
        }
        svg {
          margin-bottom: 1px;
          transform: scale(0.9);
          path {
            stroke-width: 1.6px;
          }
        }
      }
    }
    .buttons{
      display: flex;
      align-items: center;
      svg{
        cursor: pointer;
      }
        .ml-25{
            margin-left: 25px;
            margin-right: 8px;
        }
        .ml-14{
          margin-left: 14px;
        }
    }
  }