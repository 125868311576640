@media screen and (min-width: 481px) and (max-width: 1279px){
    .calendarCard-container, .no-events-card{
        width: calc( 4 * var(--col) + 3 * 20px);
    } 
}

@media screen and (max-width: 480px) {
    .calendarCard-container, .no-events-card{
        width: calc( 3 * var(--col) + 2 * 20px);
    }
}

@media screen and (min-width: 1280px) {
    .calendarCard-container, .no-events-card{
        width: calc( 3 * var(--col) + 2 * 20px);
    }
}

.calendarCard-container{
    height: calc(100% - 35px); //- padding
    background-color: #15181E;
    padding: 20px 0 15px 0;
    box-shadow: inset 0 0 0 3px var(--midnight-border-100);
    border-radius: 15px;
    .first-row{
        .actions{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        padding: 0px 30px ;
        .title{
            margin-top: 15px;
            font-weight: 700;
            color: white;
            letter-spacing: 0.32px;
            min-height: 43px;
            span{
                max-width: 400px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* Limit content to two lines */
                -webkit-box-orient: vertical;
                white-space: normal;
        }
            }
          
        p{
            margin: 0;
        }
    }
    .second-row, .third-row{
        width: calc(100% - 2 * 15px);
        padding: 12px 20px;
        box-sizing: border-box;
        margin: 15px auto 0px auto;
        border-radius: 10px;
        background: rgba(29, 34, 40, 0.52);
    }
    .second-row{

        div{
            display: flex;
            align-items: center;
            max-width: fit-content;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1; /* Limit content to two lines */
            -webkit-box-orient: horizontal;
            white-space: normal;
            max-width: 220px;

            .organization{
                
                max-width: 220px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* Limit content to two lines */
                -webkit-box-orient: vertical;
                white-space: normal;
             
            }
            .location{
               
                max-width: 220px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* Limit content to two lines */
                -webkit-box-orient: vertical;
                white-space: normal;
            }

                  span{
            color: var(--white-80, rgba(255, 255, 255, 0.80));
            font-size: 15px;
            font-family: "Satoshi" ;
            font-weight: 700;
            margin-left: 7px;

            
  

        }
        }
  
       
    }
    .third-row:hover{
        background-color: #21252C;
    }
    .third-row{
        transition: 0.3s;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        .hour{
            display: flex;
            flex-direction: column;
            span{
                color: #FFF;
                font-size: 15px;
                font-family: Satoshi ;
                font-weight: 700;
            }
            .time{
                color: #0085FF;
                font-size: 14px;
                text-transform: capitalize;
            }
        }
        .button{
            cursor: pointer;
            svg{
                width: 20px;
                color: white;
            }
        }
    }
    .show-more:hover{
        background-color: #21252C;
    }
    .show-more{
        transition: 0.3s;
        cursor: pointer;
        width: calc(100% - 2 * 15px);
        padding: 12px 20px;
        box-sizing: border-box;
        margin: 15px auto 0px auto;
        border-radius: 10px;
        background: rgba(29, 34, 40, 0.52);
        border: none;
        margin-left: 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        p{
            color: #FFF;
            font-size: 15px;
            font-family: Satoshi;
            font-weight: 700;
            margin: 0;
        }
        svg{
            width: 20px;
            color: white;
        }
    }


}

.no-events-card{
    height: calc(100% - 35px); //- padding
    background-color: #15181E;
    padding: 20px 0 20px 0;
    box-shadow: inset 0 0 0 3px var(--midnight-border-100);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    p{
        margin: 0;
        color: white;
        font-family: 'Satoshi';
        opacity: 0.8;
    }
}

.calendarCard-container-citypage{
    height: 100% !important; 
}