.fakeCarousel{
    width: 100vw;
    height: 280px;
    overflow-x: scroll ;
    display: flex;
    column-gap: 20px;
    position: relative;
}


.card-event-city-page{
  
    border-radius: 20px;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: space-between;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
    box-shadow: inset 0 0 0 3px var(--midnight-border-100);

    
    .up-row{
       
         width: 100%; 
         z-index: 2;
    }

    .down-row{
        display: flex;
         justify-content: space-between;
         align-items: end;
         width: 100%;
        //  height: 100%;
         column-gap: 10px;
        //  height: 85px;
        .event-details{
           
            width: 100%;
            // padding:  12px 20px;
            z-index: 2;
            opacity: 0.9;
            border-radius: 7px;
            font-size: 16px;

            .title{
                position: relative;
                margin-bottom: 3px;
                color: rgba(255, 255, 255, 0.70);
                font-weight: 700
            }
            p{
                margin: 0;
                font-weight: 700;
                font-size: 16px;
                color: white;
            }
           
        }
        .button{
            // height: calc(100% - 2 * 12px);
            // margin: 12px 0;
           
            z-index: 2;
            width: 20px;
            height: 20px;
            backdrop-filter: blur(10px);
            padding: 12px 13px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            border-radius: 52px;
            border: 2px solid rgba(255, 255, 255, 0.03);

            svg{
                  cursor: pointer;
            }
        }
    }
}

.card-event-city-page.opened{
    position: absolute;
    width: calc(3 * var(--col) + 2 * 20px );
    height: 100%;
    // padding: 30px 40px;
    // width: calc(100% - 2 * 40px);
    // height: calc(100% - 2 * 30px);
    


}