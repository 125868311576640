@media screen and (min-width: 1280px) {
    .city-page-vertical-carousel {
      height: 100%;
      height: 272px;
      border-radius: 15px;
      .embla__container{
        height: 100%;
        .emble__slide{
          height: 100%;
        }
      }
      .embla__bullets{
        left: -20px;
      }
    }
   

  }
  
  @media screen and (min-width: 769px)  and (max-width: 1279px) {
    .city-page-vertical-carousel {
      height: 265px;
      border-radius: 15px;
      .embla__container{
        height: calc(365px);
      }
      .embla__bullets{
        left: -20px;
      }
    }
   
  }
  
  @media screen and (min-width: 481px)  and (max-width: 768px) {
    .city-page-vertical-carousel {
      height: 320px;
      border-radius: 20px;
      .embla__container{
        height: calc(450px);
      }
      .embla__bullets{
        left: -20px;
      }
    }
    
  }
  
  @media screen and (max-width: 480px) {
    .city-page-vertical-carousel {
      height: 280px;
      border-radius: 20px;
      .embla__container{
        height: calc(450px);
      }
      .embla__bullets{
        left: -15px;
        transform: scale(0.9);
      }
    }

  }
  
  .city-page-vertical-carousel {
    --slide-spacing: 1rem;
    --slide-size: 100%;
    --slide-height: 22rem;
    /* padding: 1.6rem; */
  
  
    display: flex;
    /* margin-bottom: 100px; */
  
  /* border: 3px solid rgba(255, 255, 255, 0.08); */
  /* background: linear-gradient(90deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.00) 100%), lightgray 0px -145.907px / 133.303% 269.088% no-repeat; */
    background-color: rgba(255, 255, 255, 0.1);
    /* box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 0.13);  */
    border: 3px solid rgba(255, 255, 255, 0.1);
  


    .embla__viewport {
      display: inline;
      overflow: hidden;
      width: 100%;
      
      border-radius: 15px;
      margin-left: -8px;
      /* margin-bottom: 8px; */
      /* margin-top: -8px; */
    }
    .embla__container {
      backface-visibility: hidden;
      display: flex;
      touch-action: pan-x;
      flex-direction: column;
      border-radius: 15px;
      /* height: calc(var(--slide-spacing) + var(--slide-height)); */
      /* margin-top: calc(var(--slide-spacing) * -1); */
    }
    .embla__slide {
      flex: 0 0 calc(var(--slide-size) - 0px);
      min-height: 0;
      height: 100%;
      margin-top: 10px;
      /* padding-top: var(--slide-spacing); */
      position: relative;
      border-radius: 15px;
    }



    
    
    .embla__bullets {
      position: relative;
      /* transform: translateY(-50%); */
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    
    .embla__bullet {
      width: 8px;
      height: 16px;
      border-radius: 100px;
      background-color: white;
      margin-bottom: 6px;
      cursor: pointer;
      transition: background-color 0.3s;
      transition: 0.3s;
    }
    
    .embla__bullet.active {
      background-color: white;
      opacity: 0.2;
      width: 8px;
      height: 8px;
      transition: 0.3s;
    }
  }

  