a {
    .coworkingCard-container {
        position: relative;
        box-sizing: border-box;
        padding: 20px 0 25px 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 323px;
        // height: 100%;
        border-radius: 15px;

        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        position: relative;
        z-index: 999;
        cursor: pointer;

        .shadow {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.1);
            border-radius: 15px;
            z-index: 11;
        }

        .content-group {
            z-index: 99;

            p {
                color: #FFF;
                font-family: "Satoshi";
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 110%;
                /* 17.6px */
                letter-spacing: 0.32px;
                margin: 0;
                text-transform: capitalize;
            }
        }
    }
}



@media screen and (min-width: 1280px) {
    a {
        .fit-size {
            height: 273px;
        }
    }

}



@media screen and (min-width: 769px) and (max-width: 1279px) {
    .coworkingCard-container {
        height: 270px;
    }


}


@media screen and (min-width: 481px) and (max-width: 768px) {
    .coworkingCard-container {
        height: 320px;
    }
}



@media screen and (max-width: 480px) {
    .coworkingCard-container {
        height: 280px;
    }
}