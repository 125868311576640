.calendar-view-mobile{
  overflow: hidden;
  width: 100%;
  .container{
    display: grid;
    grid-template-columns: auto auto auto auto;
    gap: 20px;
    width: auto;
    overflow: visible;
  }
}

.calendar-view{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 20px;
  margin-bottom: var(--col);
  .month{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .header{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      //
      column-gap: 4px;
      //
      height: var(--col);
      p{
        margin: 0;
      }
      .mth{
        color: #FFF;
        font-size: 35px;
        font-style: normal;
        font-weight: 900;
        line-height: 145%; /* 50.75px */
        letter-spacing: 0.7px;
        text-transform: capitalize;
      }
      .yr{
        color: rgba(255, 255, 255, 0.42);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 145%;
        letter-spacing: 0.32px;
        //
        margin-top: 15px;
      }
      .today{
        display: flex;
        padding: 7px 20px 7px 20px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 85px;
        background: #0085FF;
        color: #FFF;
        font-size: 16px;
        font-style: normal;
        font-weight: 900;
        line-height: 145%; /* 23.2px */
        letter-spacing: 0.32px;
      }
    }
    .activities{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      //
      row-gap: 20px;
    }
    .more-button:hover{
      background: #1F232A;
    }
    .more-button{
      cursor: pointer;
      margin-top: 20px;
      font-family: 'Satoshi';
      display: flex;
      width: 228px;
      padding: 14px 21px;
      justify-content: space-between;
      align-items: center;
      border-radius: 10px;
      border: 2.7px solid #242930;
      background: #15181E;
      p{
        margin: 0;
        color: #FFF;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
      svg{
        color: #FFF;
        width: 16px;
        height: 16px;
        flex-shrink: 0;
      }
    }
  }
}