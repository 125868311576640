@media screen and (max-width: 480px) {
    .footer-container{
        width: 100%;
        padding: var(--col) 0;
        row-gap: calc(var(--col) / 2); 
        //
        .infos{
           width: calc(100% - 20px * 2);
           margin: auto;
           //
           display: flex;
           flex-direction: column;
           align-items: flex-start;
           justify-content: center;
           //
           row-gap: 0;
           .company-logo{
            height: 40px;
            margin-top: 20px;
        }
        }
        .copyright{
            display: none;
        }
       .logos{
        width: calc(100%);
        margin: auto;
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        .logo{
            width: calc(100% - 60px - 6px); //6px from border
            border-radius: 20px;
            height: 54px;
            padding: 20px 30px;
        }
       }
    } 
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .footer-container{
        width: 100%;
        padding: var(--col) 0;
        row-gap: calc(var(--col) / 2); 
        //
        .infos{
           width: calc(100% - 20px * 2);
           margin: auto;
           //
           display: flex;
           flex-direction: column;
           align-items: flex-start;
           justify-content: center;
           //
           row-gap: 0;
           .company-logo{
            height: 40px;
            margin-top: 20px;
        }
        }
        .copyright{
            display: none;
        }
       .logos{
        width: calc(100% - 10px * 2);
        margin: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        .logo{
            width: 100%;
            border-radius: 20px;
            height: 54px;
            padding: 20px 0;
            img{
                max-height: 70px;
            }
        }
       }
    } 
}

@media screen and (min-width: 769px) and (max-width: 1279px) {
    .footer-container{
        padding: var(--margin) 0;
        row-gap: calc(var(--margin) / 2);
        //
        .infos{
            width: calc(100% - 20px * 2);
            padding: 0 20px;
            //
            display: grid;
            grid-template-columns: [start] 1fr [col1] 1fr [end];
            grid-template-rows: [start] 100px [row] auto [end];
            column-gap: calc(var(--col) + 20px);
            position: relative;
            .company-logo{
                position: absolute;
                top: 0;
                right: 0;
                height: 40px;
            }
            .c1{
                grid-column-start: start;
                grid-column-end: end;
                grid-row-start: start;
                grid-row-end: row1;
            }
            .c2{
                grid-column-start: start;
                grid-column-end: col1;
                grid-row-start: row1;
                grid-row-end: end;
            }
            .c3{
                grid-column-start: col1;
                grid-column-end: end;
                grid-row-start: row1;
                grid-row-end: end;
            }
        } 
        .logos{
            height: fit-content;
            display: grid;
            gap: 20px;
            grid-template-columns: [start] repeat(2, 1fr) [end];
            grid-template-rows: [start] 100px [col1] 100px [col2] 100px [end];
            .logo{
                border-radius: 15px;
            }
        }
    } 
}

.footer-container{
    display: flex;
    flex-direction: column;
    //
    .infos{
        .c3{
            justify-self: flex-end;
        }
        .c2, .c3{
            .title{
                opacity: 0;
            }
        }
        .col{

            color: white;
            p{
                margin: 0;
            }
            .title{
                margin-bottom: 10px;
                color: #FFF;
                font-size: 18px;
                font-family: Satoshi ;
                font-weight: 500;
            }
            .content{
                color: rgba(255, 255, 255, 0.70);
 
            }
            .co1, .co2{
                margin-bottom: 4px;
            }
        }
    }
    .copyright{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #5B5D62;
        font-size: 16px;
        font-family: Satoshi;
        font-weight: 500;
        display: none;
        .vertical-line{
            width: 2px;
            height: 12px;
            border-radius: 46px;
            background: rgba(217, 217, 217, 0.22);
            
        }
        .col2{
            display: flex;
            justify-content: space-between;
            width: calc(6 * var(--col) + 5 * 20px );
            span{
                margin-right: 40px;
                cursor: pointer;
            }
            .el4{
                margin-right: 0;
            }
        }
       
    }
    .logos{
        .logo{
            border: 3px solid var(--midnight-border-100, #242930);
            background: #15181E;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

@media screen and (min-width: 1280px) {
    .footer-container{
        padding: var(--margin) 0;
        row-gap: calc(var(--margin) / 2);
        //
        .infos{
            width: calc(100% - 20px * 2);
            padding: 0 20px;
            //
            display: grid;
            grid-template-columns: [start] calc(5 * var(--col) + 4 * 20px) [col2] calc(3 * var(--col) + 2 * 20px) [col3] auto [end];
            grid-template-rows: [start] auto [end];
            column-gap: calc(var(--col) + 20px);
            .company-logo{
                height: 40px;
                margin-top: 20px;
            }
        } 
        .logos{
            height: fit-content;
            display: grid;
            column-gap: 20px;
            grid-template-columns: [start] repeat(4, 1fr) [end];
            grid-template-rows: [start] 100px [end];
            .logo{
                border-radius: 15px;
            }
        }
        .cookies-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            .company-logo{
                height: 40px;
                margin-top: 20px;
            }
            .download-cookie-button {
                cursor: pointer;
                span {
                    color: #000000;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
    
                width: fit-content;
                display: flex;
                align-items: center;
                padding: 12px 20px;
    
                border-radius: 10px;
                background: #ffffff;
    
                svg {
                    margin-left: 10px;
                }
            }
        }
    } 
}

.footer-container{
    display: flex;
    flex-direction: column;
    //
    .infos{
        .c3{
            justify-self: flex-end;
        }
        .c2, .c3{
            .title{
                opacity: 0;
            }
        }
        .col{

            color: white;
            p{
                margin: 0;
            }
            .title{
                margin-bottom: 10px;
                color: #FFF;
                font-size: 18px;
                font-family: Satoshi ;
                font-weight: 500;
            }
            .content{
                color: rgba(255, 255, 255, 0.70);
 
            }
            .co1, .co2{
                margin-bottom: 4px;
            }
            .cookies-row{
                display: flex;
                width: 100%;
                justify-content: space-between;
            }
        }
    }
    .copyright{
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #5B5D62;
        font-size: 16px;
        font-family: Satoshi;
        font-weight: 500;
        display: none;
        .vertical-line{
            width: 2px;
            height: 12px;
            border-radius: 46px;
            background: rgba(217, 217, 217, 0.22);
            
        }
        .col2{
            display: flex;
            justify-content: space-between;
            width: calc(6 * var(--col) + 5 * 20px );
            span{
                margin-right: 40px;
                cursor: pointer;
            }
            .el4{
                margin-right: 0;
            }
        }
       
    }
    .logos{
        .logo{
            border: 3px solid var(--midnight-border-100, #242930);
            background: #15181E;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    } 
}