.ask-container{
  width: calc(100% - var(--margin) * 2);
  margin: auto;
  height: calc(100vh - 88px * 2);
  margin-bottom: 88px;
  display: grid;
  grid-template-columns: calc(var(--col) * 3 + 20px * 2) calc(var(--col) * 9 + 20px * 8);
  gap: 20px;
  position: fixed;
  left: calc(var(--margin));
  .container{
    display: flex;
    box-sizing: border-box;
    border-radius: 15px;
    border: 2px solid var(--Midnight-Border-100, #242930);
    background: #15181E;
  }
}
.chat{
  position: relative;
  overflow: hidden;
  height: 100%;
  .messages-container{
    width: 100%;
    height: calc(100%);
    position: relative;
    overflow-y: scroll;
    .messages{
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: calc(100% - 40px);
      margin-left: 20px;
      margin-top: 20px;
      gap: 20px;
      .message{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        width: 100%;
        gap: 20px;
        p{
          margin: 0;
        }
        .sent-container{
          display: flex;
          align-items: flex-start;
          justify-content: flex-end;
          gap: 16px;
          width: 100%;
          .chat-avatar{
            width: 42px;
            height: 42px;
            border-radius: 100px;
            background-color: black;
            overflow: hidden;
            .chat-avatar-default{
              width: 100%;
              height: 100%;
              display: flex;
              align-items: center;
              justify-content: center;
              svg{
                width: 22px;
              }
            }
            img{
              width: 100%;
              height: 100%;
            }
          }
          p{
            padding: 10px 16px;
            background-color: #0085FF;
            border-radius: 10px;
            font-size: 16px;
            font-weight: 500;
            color: #fff;
            max-width: calc(100% - 16px - 41.5px - 40px);
          }
        }
        .received-container{
          width: 100%;
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          gap: 16px;
          .chat-avatar{
            width: 42px;
            height: 42px;
            border-radius: 100px;
            background-color: white;
          }
          .chat-avatar{
            background-color: black;
            display: flex;
            align-items:center;
            justify-content: center;
          }
          .received-mkd-container{
            width: calc(100% - 16px - 42px);
            display: flex;
            flex-direction: column;
            align-items:flex-start;
            justify-content: flex-start;
            gap: 20px;
            padding: 10px 16px;
            background-color: rgba(242, 244, 249, 0.05);
            border-radius: 10px;
            div, span, ol{
              display: flex;
              flex-direction: column;
              align-items:flex-start;
              justify-content: flex-start;
              gap: 20px;
            }
            p{
              font-size: 16px;
              font-weight: 500;
              color: #fff;
              max-width: calc(100% - 16px - 41.5px - 40px);
            }
            li{
              font-size: 16px;
              font-weight: 400;
              color: #fff;
              max-width: calc(100% - 16px - 41.5px - 40px);
            }
          }
        }
      }
    }
  }
  .input-container{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 200px;
    z-index: 999;
    z-index: 5;
    border-radius: 0 0 20px 20px;
    overflow: hidden;
    pointer-events: none;
  }
  .ask-shadow{
    width: 100%;
    height: 200px;
    position: absolute;
    left: 0;
    bottom: 0;
    background: rgb(21,24,30);
    background: linear-gradient(0deg, rgba(21,24,30,1) 30%, rgba(21,24,30,0) 100%);
    pointer-events: none;
  }
  .new-chat:hover{
    background-color: rgba(242, 244, 249, 0.07);
    svg{
      opacity: 0.9;
    }
  }
  .new-chat{
    transition: 0.3s;
    position: absolute;
    bottom: 80px;
    left: 0;
    right: 0;
    margin: auto;
    width: fit-content;
    padding: 10px 16px ;
    padding-left: 14px;
    display: flex;
    align-items: center;
    gap: 7px;
    border: none;
    border-radius: 100px;
    background-color: rgba(242, 244, 249, 0.03);
    color: white;
    backdrop-filter: blur(100px);
    cursor: pointer;
    pointer-events: all;
    svg{
      width: 16px;
      strokeWidth: 2px;
      opacity: 0.5;
      transition: 0.3s;
    }
    p{
      font-size: 16px;
      margin: 0;
      opacity: 0.9;
      font-weight: 500;
      line-height: 100%;
    }
  }
  .ask{
    position: absolute;
    bottom: 20px;
    left: 0;
    width: calc(100% - 40px);
    left: 0;
    right: 0;
    margin: auto;
    height: 44px;
    background-color: rgba(242, 244, 249, 0.05);
    border-radius: 10px;
    pointer-events: all;
    input:focus{
      outline: none;
    }
    input{
      border: none;
      background: none;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      padding: 0 16px;
      padding-right: 80px;
      border-radius: 10px;
      color: white;
      font-size: 16px;
      font-family: "Satoshi";
      font-weight: 500;
      // background-color: red;
    }
    button:hover{
      background-color: rgba(255, 255, 255, 0.05);
      p{
        opacity: 0.9;
      }
    }
    button{
      transition: 0.3s;
      cursor: pointer;
      position: absolute;
      right: 5px;
      top: 5px;
      height: 34px;
      border-radius: 7px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      padding: 0 10px;
      background-color: transparent;
      svg{
        color: white;
        width: 14px;
        strokeWidth: 2px;
      }
      p{
        transition: 0.3s;
        margin: 0;
        font-size: 14px;
        color: white;
        font-weight: 500;
        opacity: 0.5;
      }
    }
    .send_true{
      pointer-events: none;
      svg{
        opacity: 0.5;
      }
    }
  }
}

.previous{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  padding: 20px;
  .title{
    color: #FFF;
    font-family: "Satoshi";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 23.2px */
    letter-spacing: 0.48px;
    position: sticky;
    top: 20px;
  }
  .previous-chats{
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    .previous-chat:hover{
      background: rgba(242, 244, 249, 0.1);
    }
    .previous-chat{
      transition: 0.3s;
      display: flex;
      padding: 11px 20px 13px 20px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 10px;
      background: rgba(242, 244, 249, 0.05);
      color: rgba(255, 255, 255, 0.5);
      font-weight: 500;
      cursor: pointer;
    }
  }
}

.recommended{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: scroll;
  padding: 20px;
  .title{
    color: #FFF;
    font-family: "Satoshi";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 145%; /* 23.2px */
    letter-spacing: 0.48px;
    position: sticky;
    top: 20px;
  }
  .previous-chats{
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    margin-top: 40px;
    height: 100%;
    width: 100%;
    .previous-chat:hover{
      background: rgba(242, 244, 249, 0.1);
    }
    .previous-chat{
      transition: 0.3s;
      display: flex;
      padding: 11px 20px 13px 20px;
      align-items: center;
      gap: 10px;
      align-self: stretch;
      border-radius: 10px;
      background: rgba(242, 244, 249, 0.05);
      color: rgba(255, 255, 255, 0.5);
      font-weight: 500;
      cursor: pointer;
    }
  } 
}

@media screen and (min-width: 481px) and (max-width: 1279px) {
  .ask-container{
    grid-template-columns: 1fr;
    .previous, .recommended{
      display: none;
    }
    .chat{
      .input-container{
        // width: calc( 8 * var(--col) - 4px);
      }
    }
  }  
}

@media screen and (max-width: 480px) {
  .ask-container{
    width: 100%;
    margin-left: calc(-1 * var(--margin));
    grid-template-columns: 1fr;
    .previous, .recommended{
      display: none;
    }
    .chat{
      .messages{
        width: calc(100% - 10px);
        margin-left: 5px;
        margin-top: 5px;
        gap: 16px;
        .message{
          gap: 10px;
          .sent-container, .received-container{
            gap: 10px;
          }
        }
      }
      .input-container{
        width: calc(100% - 4px);
      }
    }
  }
}
