.statistics-grid-container{
    position: relative;
    display: grid;
    background-color: black;
    .line-separator{
        z-index: 1000;
        position: absolute;
        left: 0;
        width: 100%;
        height: 3px;
        background: rgb(31,31,31);
        background: linear-gradient(280deg, rgba(31,31,31,0.7) 0%, rgba(19,19,19,0.5) 100%);
    }
    .line-separator-top{
        top: 0;
    }
    .line-separator-bot{
        bottom: 0;
    }
    .left-side{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        .first-row-content{
            margin-left: var(--margin);
            //
            .content{
                p{
                    margin: 0;
                }
                .title{
                    color: #FFF;
                    font-weight: 700;
                    line-height: 110%;
                    margin-bottom: 10px;
                }
                .description{
                    color: #5B5D62;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 130%; /* 22.1px */
                }
            }

        }
        .statistics-grid{
            display: grid;
            //
            .row1, .row2{
                display: grid;
                //
                div{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    justify-content: center;
                    //
                    font-size: 18px;
                    font-weight: 500;
                    //
                    padding: 0 40px;
                    //
                    background: #15181E;
                    text-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.35);
                    border: 3px solid var(--midnight-border-100, #242930);
                    border-radius: 15px;
                    p{
                        margin: 0;
                        color: #FFFFFF4D;
                        
                    }
                    span{
                        color: white;
                    }
                }
            }
        }
    }
    .right-side{
        width: 100%;
        height: 100%;
        display: grid;
        row-gap: 20px;
        grid-template-rows: [start] 74px [row2] 334px [row3] 151px [end];
        position: relative;
        .shadow{
            position: absolute;
            background-color: red;
            width: 100%;
            height: 25%;
        }
        .shadow-top{
            top: 0;
            right: 0;
            background: rgb(0,0,0);
            background: linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
        }
        .shadow-bot{
            bottom: 0;
            right: 0;
            background: rgb(0,0,0);
            background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.7) 100%);
        }
        .row1-grid{
            .r1{
                border-radius: 0 0 15px 15px;
            }
        }
        .row2-grid{
            div{
                border-radius: 15px;
            }
            .r3{
                border-radius: 15px 0 0 15px;
            }
        }
        .row3-grid{
            .r1{
                border-radius: 15px 15px 0 0;
            }
            .r2{
                border-radius: 15px 0 0 0;
            }
        }
        .row1-grid, .row2-grid, .row3-grid{
            display: grid;
            column-gap: 10px;
        }
        .r1, .r2, .r3, .r4{
            background-size: cover;
            background-position: center center;
        }
    }
}

@media screen and (max-width: 480px) {
    .statistics-grid-container{
        grid-template-rows: [start] 1fr [mid] 1fr [end];
        row-gap:calc(var(--col));
        .left-side{
            gap: 20px;
            .first-row-content{
                margin: var(--col) 40px;
                .logo{
                    display: none;
                }
                .content{
                    margin-right: 10px;
                    .title{
                        font-size: 20px;
                    }
                    .description{
                        font-size: 16px;
                    }
                }
            }
            .statistics-grid{
                row-gap: 10px;
                width: calc(100% - var(--margin) * 2);
                grid-template-rows: [start] 1fr [row2] 1fr [end];
                .row1, .row2{
                    grid-template-rows: [start] 1fr [row2] 1fr [end];
                    row-gap: 10px;
                    div{
                        padding: 20px 30px;
                    }
                }
            }
        }
        .right-side{
            display: grid;
            width: 100%;
            grid-template-rows: 1fr 1fr 1fr;
            row-gap: 10px;
            .shadow-top{
                display: none;
            }
            .row1-grid, .row2-grid, .row3-grid{
                display: grid;
                column-gap: 10px;
                .r1{
                    border-radius: 0 20px 20px 0;
                }
                .r2{
                    border-radius: 20px 0 0 20px;
                }
                .r3, .r4{
                    display: none;
                }
            }
            .row1-grid, .row3-grid{
                grid-template-columns: 2fr 3fr;
            }
            .row2-grid{
                grid-template-columns: 3fr 2fr;
            }
            .row3-grid{
                .r1{
                    border-radius: 0 20px 0 0;
                }
                .r2{
                    border-radius: 20px 0 0 0;
                } 
            }
        }
    }
}


@media screen and (min-width: 481px) {
    .statistics-grid-container{
        grid-template-columns: [start] 1fr [mid] 1fr [end];
        column-gap:calc(var(--col));
        overflow: hidden;

        //
        .left-side{
            row-gap: 70px;
            height: 600px;
             .first-row-content{
                display: grid;
                column-gap: 20px;
                grid-template-columns: [start] var(--col) [col2] auto [end];
                .logo{
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: start;
                }
                .content{
                    .title{
                        font-size: 25px;
                    }
                    .description{
                        font-size: 17px;
                    }
                }
            }
            .statistics-grid{
                margin-left: var(--margin);
                //
                row-gap: 20px;
                grid-template-rows: [start] 102px [row2] 102px [end];   
                .row1{
                    display: grid;
                    grid-template-columns: [start] calc(2 * var(--col) + 2 * 20px) [col2] calc(3 * var(--col) + 2 * 20px )  [end];
                    column-gap: 20px;
                }
                .row2{
                    display: grid;
                    grid-template-columns: [start] calc(3 * var(--col) + 20px) [col2] auto  [end];
                    column-gap: 20px;
                    div{
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;
                        justify-content: center;
                        //
                        font-size: 18px;
                        font-weight: 500;
                        //
                        padding: 0 40px;
                        //
                        background: #15181E;
                        text-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.35);
                        border: 3px solid var(--midnight-border-100, #242930);
                        border-radius: 15px;
                        p{
                            margin: 0;color: #FFFFFF4D;
                            
                        }
                        span{
                            color: white;
                        }
                    }
    
                }
            }
        }
        .right-side{
            .row1-grid{
                display: grid;
                column-gap: 20px;
                grid-template-columns: [start] calc(5 * var(--col) + 4 * 20px) [col2] auto [end];
                .row1.r1{
                    border-radius: 0px 0px 15px 15px;
                }
                .row1.r2{
                    border-radius: 0px 0px 0px 15px;
                }
            }
            .row2-grid{
                display: grid;
                gap: 20px;
                grid-template-columns: [start] calc(2 * var(--col) + 20px) [col2]  calc(2 * var(--col) + 20px) [col3]auto [end];
                grid-template-rows: [start] 164px [row2] 150px [end];
                .r1{
                    grid-column-start: start;
                    grid-column-end: col2;
                }
                .r2{
                    grid-column-start: col2;
                    grid-column-end: col3;
                }
                .r3{
                    grid-column-start: col3;
                    grid-column-end: end;
                    grid-row-start: start;
                    grid-row-end: end;
                    
                }
                .r4{
                    grid-column-start: start;
                    grid-column-end: col3;
                    grid-row-start: row2;
                    grid-row-end: end;
                }
                .row2{
                    border-radius: 15px;
                }
                .row2.r3{
                    border-radius: 15px 0px 0px 15px;
                }
            }
            .row3-grid{
                grid-row-start: row3;
                grid-row-end: end;
                display: grid;
                column-gap: 20px;
                grid-template-columns: [start] calc(5 * var(--col) + 4 * 20px) [col2] auto [end];
                .row3.r1{
                    border-radius:  15px 15px 0px 0px;
                }
                .row3.r2{
                    border-radius:  15px 0px 0px 0px;
                }
            }
        }
    }
    
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .statistics-grid-container{
        width: 100%;
        overflow: hidden;
        grid-template-columns: [start] auto [mid] 200px [end];
        //
        .left-side{
            .statistics-grid{
                grid-template-rows: auto auto;
                .row1, .row2{
                    div{
                        height: 80px;
                        // width: 100%;
                        padding-left: 30px;
                        padding-right: 30px;
                        span{
                            font-size: 17px;
                        }
                        p{
                            font-size: 15px;
                        }
                    }
                }
              }
        }
    }   
}