.content-modal-shadow{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba(255, 255, 255, 0.01);
        backdrop-filter: blur(140px);
        z-index: 999; /* Ensure the clipRule is above other elements */

}

.content-modal{
  box-sizing: border-box;
  width: calc(100% - 2 * var(--margin));
  height: calc(100% - 2 * var(--margin));
  max-width: 1316px;
  background-color: white;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1000;
  border-radius: 15px;
  border: 3px solid rgba(255, 255, 255, 0.08);
  background: #1A1C1F;
  overflow-y: scroll;
  .topbar-activity{
    justify-content: space-between;
  }
  .topbar-video{
    justify-content: flex-end;
    // width: 100vw;
  }
  .topbar{
    position: fixed;
    z-index: 10001;
    // width: calc(100% - 2 * var(--margin) - 40px * 2);
    width: calc(100% );
    display: flex;
    align-items: center;
    padding: 30px 40px;
    box-sizing: border-box;
    .add-to-calendar:hover{
      border: 2px solid rgba(255, 255, 255, 1);
      transition: 0.4s;
    }
    .add-to-calendar{
      transition: 0.4s;
      cursor: pointer;
      display: flex;
      height: 40px;
      padding: 10px 18px;
      justify-content: center;
      align-items: center;
      gap: 19px;
      border-radius: 10px;
      background: #0085FF;
      border: 2px solid rgba(255, 255, 255, 0);
      p{
        color: #FFF;
        font-size: 15px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%; /* 16.5px */
        letter-spacing: 0.15px;
        margin: 0;
        margin-top: 2px;
      }
      .add{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        svg{
          width: 20px;
          height: 20px;
          color: white;
          strokeWidth: 2px;
          margin-left: -5px;
        }
      }
      .separator{
        border-radius: 46px;
        background: rgba(217, 217, 217, 0.22);
        width: 2px;
        height: 12px;
      }
      .date{
        opacity: 0.75;
        text-transform: capitalize;
      }
    }
    .close:hover{
      background-color: #ff4259;
      transition: 0.4s;
    }
    .close{
      // transition: 0.4s;
      cursor: pointer;
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      gap: 20px;
      flex-shrink: 0;
      border-radius: 52px;
      border: 2px solid rgba(255, 255, 255, 0.03);
      background: rgba(31, 34, 41, 0.60);
      backdrop-filter: blur(65.5px);
      svg{
        color: white;
        width: 20px;
        strokeWidth: 2px;
        opacity: 0.9;
      }
    }
  }
  .hero{
    width: 100%;
  }
  .hero-activity{
    height: 400px;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    object-fit: cover;
  }
  .hero-video{
    width: calc(100%);
    aspect-ratio: 1.7;
    position: relative;
    z-index: 10000;
    video{
      object-fit: cover;
    }
  }
  .description{
    .first-row{
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 25px 40px;
      padding-bottom: 0;
      p{
        margin: 0;
      }
      .title{
        color: #FFF;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }
    .organizer{
      padding: 10px 40px;
      padding-top: 10px;
      display: flex;
      gap: 1ch;
      p{
        margin: 0;
        color: #FFF;
        opacity: 0.5;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%; /* 17.6px */
        letter-spacing: 0.32px;
      }
     .org{
      opacity: 0.9;
     }
    }
    .location{
      padding: 10px 40px;
      padding-top: 0px;
      display: flex;
      gap: 1ch;
      p{
        margin: 0;
        color: #FFF;
        opacity: 0.5;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%; /* 17.6px */
        letter-spacing: 0.32px;
      }
     .org{
      opacity: 0.9;
     }
    }
    .time{
      padding: 25px 40px;
      padding-top: 0px;
      display: flex;
      gap: 1ch;
      p{
        margin: 0;
        color: #FFF;
        opacity: 0.5;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%; /* 17.6px */
        letter-spacing: 0.32px;
      }
     .org{
      opacity: 0.9;
     }
     
    }
    .second-row{
      padding: 25px 40px;
      p{
        margin: 0;
      }
      .description{
        color: #FFF;
        opacity: 0.5;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%; /* 17.6px */
        letter-spacing: 0.32px;
      }
    }
  }
  .recommended{
    padding: 0 20px;
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .recommended-card{
      width: 100%;
    }
  }
  .ending{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 30px;
    p{
      color: #FFF;
      opacity: 0.25;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }
  }
  .separator{
    height: 3px;
    width: calc(100% - 2 * var(--margin) );
    border-radius: 30px;
    background: rgba(233, 244, 255, 0.03);
    margin: 20px auto;
  }
}

@media screen and (max-width: 768px) {
  .content-modal{
    height: calc(100vh - var(--col) * 2 - 80px);
    .topbar{
      padding: 20px;
    }
    .topbar-activity{
      .add-to-calendar{
        gap: 10px;
      .default-add-text, .separator{
        display: none;
      }
      }
    }
    .description{
      .first-row, .second-row{
        padding: 25px;
      }
      .first-row{
        gap: 20px;
      }
      .second-row{
        padding-top: 0;
      }
    }
  }
}