.card-actions:hover{
  transition: 0.4s;
  border: 2px solid rgba(255, 255, 255, 0.5);
}
.card-actions{
  transition: 0.4s;
  display: flex;
  // padding: 11px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  border: 2px solid rgba(255, 255, 255, 0.15);
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), rgba(255, 255, 255, 0.20);
  backdrop-filter: blur(25px);
  button{
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    padding: 10px 16px;
  }
  svg:hover{
    opacity: 1;
    transition: 0.4s;
    cursor: pointer;
  }
  svg{
    transition: 0.4s;
    opacity: 0.5;
    // background-color: red;
    // padding: 10px 16px;
  }
  .liked-heart{
    opacity: 1;
  }
  .separator{
    width: 2px;
    height: 12px;
    border-radius: 46px;
    background: rgba(255, 255, 255, 0.20);
  }
}