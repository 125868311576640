.cities-grid-container {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 20px;
    .city-buttons-container {
        width: 100%;
        overflow: scroll;
        position: relative;
        border-radius: 10px;
        .city-buttons {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: #FFFFFF54;
            gap: 16px;
            .city-button{
                transition: 0.3s;
                cursor: pointer;
                white-space: nowrap;
                background: rgba(36, 41, 48, 0.6);
                color: rgba(255, 255, 255, 0.8);
                margin: 0;
                display: inline-flex;
                padding: 0 20px;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%; /* 19.6px */
                letter-spacing: 0.28px;
                font-family: 'Satoshi';
                height: 44px;
                p{
                    margin: 0;
                    user-select: none
                }
            }
            .city-button-active {
                background-color: #0085FF;
                color: rgba(255, 255, 255, 1);
                font-weight: 600;
            }
        }
    }
}