.cities-grid {
    display: grid;
    grid-template-columns: [start] calc(6 * var(--col) + 5 * 20px) [half] calc(6 * var(--col) + 5 * 20px) [end];
    column-gap: 20px;


    .left-side {
        grid-column-start: start;
        grid-column-end: half;
        display: grid;
        grid-template-rows: [start] 100% [end];
        row-gap: 20px;
        position: relative;
        .shadow{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.2);
            border-radius: 15px;
            z-index: 11;
        }

        .city-title-section {
            // background-image: url("https://images.unsplash.com/photo-1611784237828-7cb6c4d64959?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1171&q=80");
            border-radius: 20px;
            display: flex;
            align-items: start;
            flex-direction: column;
            justify-content: space-between;
            background-position: center left;
            background-repeat: no-repeat;
            background-size: cover;
            object-fit: cover;
            color: white;
            padding: 30px 40px;
            box-sizing: border-box;
            // overflow: hidden;
            // border: 3px solid rgba(208, 206, 206, 0.155);
            box-shadow: inset 0 0 0 3px rgba(208, 206, 206, 0.155); /* Adjust the values as needed */
            // box-shadow: inset 0 0 0 3px rgba(33, 33, 33, 0.955); /* better value for border to be discussed */

            position: relative;
                       
            .up-row { 
                z-index: 12;
                .content {
                    position: relative;
                    .title {
                        z-index: 2;
                        color: #FFF;
                        font-size: 30px;
                        font-family: Satoshi;
                        font-weight: 900;
                        line-height: 110%;
                        letter-spacing: -0.6px;
                        text-transform: uppercase;
                        margin: 0;
                    }

                    .description {
                        z-index: 3;
                        margin: 0;
                        margin-top: 3px;
                        color: rgba(255, 255, 255, 0.63);
                        font-size: 16px;
                        font-family: Satoshi;
                        font-weight: 700;
                        line-height: 110%;
                        letter-spacing: 0.32px;
                    }
                }
            }

            .down-row {
                z-index: 12;
                display: flex;
                justify-content: space-between;
                align-items: end;
                width: 100%;
                //  height: 100%;
                column-gap: 0px;

                //  height: 85px;
                .event-details {
                    position: relative; 
                    z-index: 2;
                    width: 100%;
                    // padding:  12px 20px;

                    opacity: 0.9;
                    border-radius: 7px;
                    font-size: 16px;

                    p {                      
                        margin: 0;
                        color: #FFF;
                        text-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
                        font-size: 16px;
                        font-family: Satoshi;
                        font-weight: 700;
                        line-height: 135%;
                        letter-spacing: 0.16px;
                    }

                }

                .button {
                    position: relative; 
                    z-index: 2;
                    background: #0085FF;
                    width: 40px;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    border-radius: 50%;
                    // border: 3px solid #0159AA;
                    background: #0085FF;
                    backdrop-filter: blur(22.5px);
                    box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.3);

                }
            }
        }
        .big-bar {
            width: calc(100% - 40px - 40px - 6px);
            height: calc(100% - 25px - 25px - 6px);
            padding: 25px 40px 25px 40px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .content {
                p {
                    margin: 0;

                    font-size: 15px;
                }

                .title {
                    line-height: 145%;
                    // font-family: "Satoshi";
                    font-weight: 700;
                }

                .description {
                    width: calc(3 * var(--col) + 2 * 20px);
                    line-height: 145%;
                    font-weight: 700;
                    // color: rgba(255, 255, 255, 0.8);
                    // text-shadow: 0px 4px 6px rgba(0, 0, 0, 0.35);
                }
            }

            .button {
                background: #0085FF;
                border-radius: 50%;
                width: 41px - 6px;
                height: 41px - 6px; // - border
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                border: 3px solid #0159AA;
            }
        }
        .big-bar.blackbg {
            background: #111111;
            border: 3px solid rgba(255, 255, 255, 0.04);
            color: white;

            .description {
                color: #FFFFFFB2;
            }

            .button {
                border: 3px solid #0159AA;
            }
        }
    }
    .right-side {
        display: grid;
        grid-template-rows: repeat(2, 1fr);
        row-gap: 20px;
        .featured-card{
            height: 100%;
        }
    }
}

@media screen and (min-width: 481px) and (max-width:1279px) {
    .cities-grid {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .left-side {
            grid-column-start: start;
            grid-column-end: half;
            display: grid;
            grid-template-rows: [start] 350px [end]; 
        }
        .right-side{
            position: relative;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: [start] 100% [end]; 
            gap: 20px;
            // height: 374px;
            .featured-card{
                // height: 374px;
            }
        }
        
    }    
}

@media screen and (max-width:480px) {
    .cities-grid-container {
        .city-buttons {
            width: 100%;
        }
        .cities-grid {
            display: flex;
            flex-direction: column;
            gap: 20px;
            .left-side{
                width: 100%;
                height: 450px;
                .city-title-section{
                    height: 450px;
                    padding: 20px 30px;
                }
            }
            .right-side{
                position: relative;
                display: flex;
                flex-direction: column;
                .featured-card{
                    height: 374px;
                }
            }
            
        }    
    }
}
