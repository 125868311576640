.featured-carousel{
  overflow: hidden;
  width: 100%;
  .container{
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
    gap: 20px;
    width: auto;
    overflow: visible;
    .card{
      height: 380px;
      ////////////////////////////////
      width: calc(var(--col) * 2 + 20px);
    }
  }
}