@media screen and (min-width: 1280px) {
    .autovalutazioneCard-container {
        .description-row {
            height: 130px;
    
         
    
        }
    }

}



.autovalutazioneCard-container {
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    height: fit-content;

    display: flex;

    flex-direction: column;
    align-items: flex-start;

    border-radius: 15px;
    border: 2px solid var(--Midnight-Border-100, #242930);
    background: #15181E;
    font-family: "Satoshi";

    .images-row {
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 30px;

        .images {
            display: flex;
            align-items: center;
            padding-left: 20px;

            .square-image {
                width: 103px;
                height: 103px;
                border-radius: 10px;
                // border: 2px solid rgba(255, 255, 255, 0.10);
                z-index: 2;
                object-fit: cover;
                background-position: center center;
                background-size: cover;
            }

            .rounded-avatar {
                background-repeat: no-repeat;
                background-size: cover;
                background-position: center center;
                z-index: 1;
                margin-left: -40px;
                width: 83px;
                height: 83px;
                border-radius: 41.715px;
                border: 1.62px solid rgba(255, 255, 255, 0.08);
            }
        }

        .title-group {

            margin-left: 20px;

            p {
                margin: 0;
            }

            .title {
                color: #FFF;
                font-family: "Satoshi";
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .subtitle {
                color: rgba(255, 255, 255, 0.50);
                font-family: "Satoshi";
                font-size: 16px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
                /* 19.2px */
                letter-spacing: 0.48px;
            }
        }
    }



    .description-row {
        display: flex;
        padding: 12px 20px 16px 20px;
        align-items: flex-start;

        border-radius: 10px;
        background: rgba(37, 43, 50, 0.52);

        p {
            color: rgba(255, 255, 255, 0.40);
            margin: 0;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: 135%;
            /* 20.25px */
        }

    }

    .statistics-row {
        width: 100%;

        margin-top: 10px;
        display: flex;
        column-gap: 10px;
        height: 80px;

        .statistics {
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-around;
            width: 100%;
            padding: 20px 14px;
            

            border-radius: 10px;
            background: rgba(37, 43, 50, 0.52);
            width: calc(100% - 80px - 10px);

            .stat {
                .how-many {
                    color: #FFF;
                    text-align: center;
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                span {
                    color: rgba(255, 255, 255, 0.50);


                }
            }
        }

        .form-button {
            display: flex;
            // padding: 14px 20px;
            width: 80px;
            height: 80px;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            background: rgba(37, 43, 50, 0.52);

            .icon {
                cursor: pointer;
                display: flex;
                width: 40px;
                height: 40px;
                justify-content: center;
                align-items: center;
                border-radius: 35px;
                // border: 2px solid rgba(255, 255, 255, 0.30);
                box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.30);
                background: #0085FF;
            }
        }
    }
}