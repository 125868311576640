@media screen and (max-width: 480px) {
    .menu {
        position: fixed;
        top: 88px; /* Adjust the top position to fit your layout */
        // width: calc(100vw - 20px - 40px);
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        z-index: 999; /* Ensure the menu appears above other elements */
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border-radius: 10px;
        border: 2px solid rgba(255, 255, 255, 0.03);
        background: rgba(31, 34, 41, 0.8);
        backdrop-filter: blur(35px);
        color: rgba(255, 255, 255, 0.8);
        font-family: "Satoshi";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        box-sizing: border-box;
        width: calc(100% - 2 * var(--margin) - 20px);
        left: calc(var(--margin) + 10px);
  
        a:hover {
          color: #0085ff; /* Change the color on hover */
        }
        .mio-spazio-ham{
          width: 100%;
          box-sizing: border-box;
          
        }
  
        .mio-spazio-ham a {
          box-sizing: border-box;
          color: white;
          text-decoration: none;
          transition: color 0.3s ease-in-out;
          cursor: pointer;
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 14px 20px;
          align-items: center;
          border-radius: 7px;
          background: rgba(21, 24, 30, 0.6);
  
          .avatar {
            border-radius: 50%;
            width: 25px;
            height: 25px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
          }
        }
  
        .rest-of-pages {
          width: 100%;
          box-sizing: border-box;
          border-radius: 7px;
          background: rgba(21, 24, 30, 0.6);
  
          .ham-hor-line {
            width: 204px;
            height: 1px;
            border-radius: 46px;
            background: rgba(217, 217, 217, 0.03);
            margin: 6px auto;
          }
  
          a {
            width: 100%;
            box-sizing: border-box;
            color: white;
            text-decoration: none;
            transition: color 0.3s ease-in-out;
            cursor: pointer;
            // width: 244px;
            display: flex;
            justify-content: space-between;
            padding: 14px 20px;
            align-items: center;
          }
        }
  
        .log-out{
          width: 100%;
          box-sizing: border-box;
        }
        .log-out a {
          width: 100%;
          box-sizing: border-box;
          color: white;
          text-decoration: none;
          transition: color 0.3s ease-in-out;
          cursor: pointer;
          // width: 244px;
          display: flex;
          justify-content: space-between;
          padding: 14px 20px;
          align-items: center;
          border-radius: 7px;
          background: #ff4259;
        }
  
        /* Add a transition to smoothly show/hide the menu */
      }
}




@media screen and (min-width: 568px) and (max-width: 1279px) {

    .menu {
        position: fixed;
        top: 88px; /* Adjust the top position to fit your layout */
        // width: calc(100vw - 20px - 40px);
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        z-index: 999; /* Ensure the menu appears above other elements */
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border-radius: 10px;
        border: 2px solid rgba(255, 255, 255, 0.03);
        background: rgba(31, 34, 41, 0.8);
        backdrop-filter: blur(35px);
        color: rgba(255, 255, 255, 0.8);
        font-family: "Satoshi";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
  
        a:hover {
          color: #0085ff; /* Change the color on hover */
        }
  
        .mio-spazio-ham a {
          color: white;
          text-decoration: none;
          transition: color 0.3s ease-in-out;
          cursor: pointer;
          width: 244px;
          display: flex;
          justify-content: space-between;
          padding: 14px 20px;
          align-items: center;
          border-radius: 7px;
          background: rgba(21, 24, 30, 0.6);
  
          .avatar {
            border-radius: 50%;
            width: 25px;
            height: 25px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
          }
        }
  
        .rest-of-pages {
          border-radius: 7px;
          background: rgba(21, 24, 30, 0.6);
  
          .ham-hor-line {
            width: 204px;
            height: 1px;
            border-radius: 46px;
            background: rgba(217, 217, 217, 0.03);
            margin: 6px auto;
          }
  
          a {
            color: white;
            text-decoration: none;
            transition: color 0.3s ease-in-out;
            cursor: pointer;
            width: 244px;
            display: flex;
            justify-content: space-between;
            padding: 14px 20px;
            align-items: center;
          }
        }
  
        .log-out a {
          color: white;
          text-decoration: none;
          transition: color 0.3s ease-in-out;
          cursor: pointer;
          width: 244px;
          display: flex;
          justify-content: space-between;
          padding: 14px 20px;
          align-items: center;
          border-radius: 7px;
          background: #ff4259;
        }
  
        /* Add a transition to smoothly show/hide the menu */
      }

}




@media screen and (min-width: 1280px) {
    .menu {
        position: absolute;
        top: 60px; /* Adjust the top position to fit your layout */
        right: 0;
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        z-index: 999; /* Ensure the menu appears above other elements */
        padding: 20px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        border-radius: 10px;
        border: 2px solid rgba(255, 255, 255, 0.03);
        background: rgba(31, 34, 41, 0.8);
        backdrop-filter: blur(35px);
        color: rgba(255, 255, 255, 0.8);
        font-family: "Satoshi";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;

        a:hover {
          color: #0085ff; /* Change the color on hover */
        }

        .mio-spazio-ham a {
          color: white;
          text-decoration: none;
          transition: color 0.3s ease-in-out;
          cursor: pointer;
          width: 244px;
          display: flex;
          justify-content: space-between;
          padding: 14px 20px;
          align-items: center;
          border-radius: 7px;
          background: rgba(21, 24, 30, 0.6);

          .avatar {
            border-radius: 50%;
            width: 25px;
            height: 25px;
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
          }
        }

        .rest-of-pages {
          border-radius: 7px;
          background: rgba(21, 24, 30, 0.6);

          .ham-hor-line {
            width: 204px;
            height: 1px;
            border-radius: 46px;
            background: rgba(217, 217, 217, 0.03);
            margin: 6px auto;
          }

          a {
            color: white;
            text-decoration: none;
            transition: color 0.3s ease-in-out;
            cursor: pointer;
            width: 244px;
            display: flex;
            justify-content: space-between;
            padding: 14px 20px;
            align-items: center;
          }
        }

        .log-out a {
          color: white;
          text-decoration: none;
          transition: color 0.3s ease-in-out;
          cursor: pointer;
          width: 244px;
          display: flex;
          justify-content: space-between;
          padding: 14px 20px;
          align-items: center;
          border-radius: 7px;
          background: #ff4259;
        }

        /* Add a transition to smoothly show/hide the menu */
      }

      .noDesktop{
        display: none;
      }
}




@media screen and (min-width: 1685px) {}