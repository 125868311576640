
.seeMoreBanner-container {
    display: flex;
    overflow: hidden;
    //
    border: 3px solid rgba(36, 41, 48, 0.70);
    box-sizing: border-box;
    background: #15181E;
    //
    .left-side {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        //
        .content {
            p{
                margin: 0;
            }
            .title {
                color: #FFF;
                font-style: normal;
                font-weight: 700;
                line-height: 135%;
                margin-bottom: 3px;
                /* 21.6px */
            }
            .subtitle {
                color: rgba(255, 255, 255, 0.70);
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                /* 18px */
            }
        }
        .button {
            cursor: pointer;
            span {
                color: #FFF;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            width: fit-content;
            display: flex;
            align-items: center;
            padding: 12px 20px;

            border-radius: 10px;
            background: #0085FF;

            svg {
                margin-left: 10px;
            }
        }
        .button:hover{
            opacity: 0.8;
        }
    }
    .right-side{
        position: relative;
        overflow: hidden;
       img{
        position: absolute;
        right: -40px;
        height: 100%;
        object-fit: cover;
        background-position: center;
       }
       .shadow{
        background: rgb(21,24,30);
        background: linear-gradient(90deg, rgba(21,24,30,0) 0%, rgba(9,10,13,1) 100%);
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;    
        height: 100%;
       }
    }


}

@media screen and (max-width: 480px) {
    .seeMoreBanner-container {
        min-height: 180px;
        height: fit-content;
        margin-top: 20px;
        // 
        border-radius: 20px;
        .left-side{
            padding: 20px;
            width: calc(100% - 20px * 2);
            gap: 20px;
            //
            .content {
                padding: 0 4px;
                .title {     
                    font-size: 15px;
                }
                .subtitle {    
                    font-size: 14px;
                }
            }
        }
        .right-side{
            display: none;
        }
    }
}

@media screen and (min-width: 481px) and (max-width: 768px) {
    .seeMoreBanner-container {
        height: 180px;
        margin-top: 20px;
        // 
        border-radius: 20px;
        .left-side{
            padding: 20px;
            width: calc(100% - 20px * 2);
            //
            .content {
                padding: 0 4px;
                .title {     
                    font-size: 15px;
                }
                .subtitle {    
                    font-size: 14px;
                }
            }
        }
        .right-side{
            display: none;
        }
    }
}

@media screen and (min-width: 769px) and (max-width: 1279px) {
    .seeMoreBanner-container {
        margin-top: 20px;
        //
        width: 100%;
        height: 300px;  
        //
        border-radius: 15px;
        .left-side{
            padding: 40px;
            width: calc(var(--col) * 5 + 5 * 20px);
            //
            .content {
                .title {     
                    font-size: 16px;
                }
                .subtitle {    
                    font-size: 15px;
                }
            }
        }
        .right-side {
            display: none;
            img {
                height: 200%;
                right: -100px;
                bottom: -150px;
            }
        }
    } 
}

@media screen and (min-width: 1280px) {
    .seeMoreBanner-container {
        margin-top: 20px;
        //
        width: 100%;
        height: 300px;  
        //
        border-radius: 15px;
        gap: 20px;
        .left-side{
            padding: 40px;
            width: calc(var(--col) * 9 + 9 * 20px);
            //
            .content {
                .title {     
                    font-size: 16px;
                }
                .subtitle {    
                    font-size: 15px;
                }
            }
        }
        .right-side {
            width: 100%;
            img {
                position: relative;
                width: 100%;
                height: 100%;
                border-radius: 0 15px 15px 0;
                background-size: cover;
                background-repeat: no-repeat;
                object-fit: cover;
                background-position: center center;
    
            }
        }
    } 
}
