@media screen and (max-width: 768px) {
    .carousel {
      position: relative;
      width: 100%;
      height: 430px;
      .carouselContent {
        top: 0;
        height: 400px;
        width: 100%;
        // height: 100%;
        .card-content{
          .card-image{
            height: 230px!important;
          }
        }
      }
      /* Add this CSS in your SCSS or CSS file */
            .carouselBullets {
              display: flex;
              justify-content: center;
              align-items: center;
              position: absolute;
              width: 100%;
              bottom: 0;
      
              .bullet {
                width: 8px;
                height: 8px;
                border-radius: 100px;
                background-color: #ccc;
                margin-bottom: 6px;
                cursor: pointer;
                transition: background-color 0.3s;
                margin-left: 5px;
                opacity: 0.2;
                //
                transition: 0.3s;
              }
      
              .bullet.active {
                width: 16px;
                height: 8px;
                border-radius: 100px;
                background-color: white;
                opacity: 1;
                /* Change to your desired active color */
                transition: 0.3s;
              }
            }
      .carouselBar {
        width: calc(100% - 37px * 2);
        height: 2px;
        background-color: #f0f1f6;
        position: absolute;
        bottom: 0;
        display: flex;
        column-gap: 20px;
      
        .carouselFilled {
          height: 100%;
          background-color: var(--red);
        } 
        .carouselFilled2 {
          height: 100%;
          background-color: var(--red);
        } 
        .carouselFilled3 {
          height: 100%;
          background-color: var(--red);
        } 
      }
    }
    }
    
    @media screen and (min-width: 768px) {
      .carousel {
        display: none;
      }
    }
    