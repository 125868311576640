.logged-in-cities-modal{
  position: absolute;
  right: 0px;

  top: 120px;
}
.not-logged-in-cities-modal{
  position: absolute;
  right: 0px;

  top: -20px;
}

.cities-menu {
    position: absolute;
    top: 60px; /* Adjust the top position to fit your layout */
    right: 0px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    z-index: 999; /* Ensure the menu appears above other elements */
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-radius: 10px;
    border: 2px solid rgba(255, 255, 255, 0.03);
    background: rgba(31, 34, 41, 0.8);
    backdrop-filter: blur(35px);
    color: rgba(255, 255, 255, 0.8);
    font-family: "Satoshi";
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    .rest-of-pages {
      a:hover {
        color: #0085ff; /* Change the color on hover */
      }
      border-radius: 7px;
      background: rgba(21, 24, 30, 0.6);


      a {
        color: white;
        text-decoration: none;
        transition: color 0.3s ease-in-out;
        cursor: pointer;
        width: 144px;
        display: flex;
        justify-content: space-between;
        padding: 14px 20px;
        align-items: center;
      }
    }



  }



  @media screen and (min-width: 1280px) {
    .display-none-cities-modal{
        display: none;
    }

    
 
  }