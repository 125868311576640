.filter-button, .filter-option{
  border: none;
  gap: 10px;
  cursor: pointer;
  svg{
    width: 18px;
  }
  p{
    margin: 0;
  }
}

.filter-button:hover{
  background-color: #dfe3ee;
  color: #434c65;
}

.filter-container{
  z-index: 1000;
  position: relative;
  //
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //
  padding: 10px !important;
  gap: 10px;
  .close-modal{
    position: absolute;
    right: -37px;
    top: 10px;
    //
    display: flex;
    align-items: center;
    justify-content: center;
    //
    border: none;
    border-radius: 100px;
    background-color: #242930;
    color: white;
    cursor: pointer;
    //
    width: fit-content;
    aspect-ratio: 1;
    svg{
      width: 16px;
    }
  }
  .filter-option{
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 16px;
    background-color: #dfe3ee;
    border-radius: 5px;
    color: #434c65;
  }
  .filter-option:hover{
    background-color: #c7ccdc;
  }
}