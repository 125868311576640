.carouselsGrid-container__title {
    color: #161616;
    font-family: "Satoshi" ;
    font-size: 35px;
    font-style: normal;
    font-weight: 900;
    line-height: 110%;
    /* 38.5px */
    letter-spacing: -0.7px;
    margin-left: 20px;

}






@media screen and (min-width: 1280px) {
    .carouselsGrid-container {
        // background-color: rgb(73, 71, 71);
        position: relative;
        // padding: 60px 0 98px 0;
        margin-top: 75px;
        margin-left: var(--margin);
        margin-right: var(--margin);
    
        display: grid;
        max-height: 405px;
        grid-template-rows: [start] minmax(325px, 345px) [end];
        grid-template-columns: [start] calc(5 * var(--col) + 4 * 20px) [space-start] calc(var(--col) + 2 * 20px) [col1] auto [end];
    
        .vertical-bare-container {
            display: flex;
            align-items: center;
            justify-content: center;
    
            .vertical-bare {
                border-radius: 72px;
                background: rgba(217, 217, 217, 0.11);
                width: 2px;
                height: calc(100% - 50px);
                bottom: 0;
                position: absolute;
            }
        }
    
        .vertical-carousel-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 20px;
            .ver-carousel-title {
                display: flex;
                // height: 60px;
               .bg{
                background: rgba(255, 255, 255, 0.05);
                color: rgba(255, 255, 255, 0.8);
                justify-content: center;
                align-items: center;
                display: inline-flex;
    
                padding: 8px 16px;
                
                border-radius: 10px;
                box-sizing: border-box;
                  span{
                 
                   
                
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 140%;
                letter-spacing: 0.28px;
                font-family: "Satoshi";
                /* 21.6px */
                // margin-bottom: 20px;
                }
               }
              
            }
            .vertical-carousel-cp{
                // height: calc(100% - 60px);
            }
        }
    
        .horisontal-carousel-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 20px;
            .hor-carousel-title {
                display: flex;
                height: 40px;
                
                .bg{
                    background: rgba(255, 255, 255, 0.05);
                    color: rgba(255, 255, 255, 0.8);
                    justify-content: center;
                    align-items: center;
                    display: inline-flex;
        
                    padding: 8px 16px;
                    
                    border-radius: 10px;
                    box-sizing: border-box;
                      span{
                     
                       
                    
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%;
                    letter-spacing: 0.28px;
                    font-family: "Satoshi";
                    /* 21.6px */
                    // margin-bottom: 20px;
                    }
                   }
           
            }
    
            .calendar-cards{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 100%;
                column-gap: 20px;
    
            }
        }
    
    }
  }
  
  @media screen and (min-width: 768px) and (max-width: 1279px) {
    .carouselsGrid-container {
        // background-color: rgb(73, 71, 71);
        position: relative;
        // padding: 60px 0 98px 0;
        margin-top: 75px;
        margin-left: var(--margin);
        margin-right: var(--margin);
    
        display: flex;
        flex-direction: column;
        row-gap: 40px;
    
        .vertical-bare-container {
            display: none;
            
        }
    
        .vertical-carousel-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 20px;
            .ver-carousel-title {
                display: flex;
                height: 40px;
                .bg{
                    background: rgba(255, 255, 255, 0.05);
                    color: rgba(255, 255, 255, 0.8);
                    justify-content: center;
                    align-items: center;
                    display: inline-flex;
        
                    padding: 8px 16px;
                    
                    border-radius: 10px;
                    box-sizing: border-box;
                      span{
                     
                       
                    
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%;
                    letter-spacing: 0.28px;
                    font-family: "Satoshi";
                    /* 21.6px */
                    // margin-bottom: 20px;
                    }
                   }
            }
            .vertical-carousel-cp{
                height: calc(100% - 40px);
            }
        }
    
        .horisontal-carousel-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 20px;
            .hor-carousel-title {
                display: flex;
                height: 40px;
                .bg{
                    background: rgba(255, 255, 255, 0.05);
                    color: rgba(255, 255, 255, 0.8);
                    justify-content: center;
                    align-items: center;
                    display: inline-flex;
        
                    padding: 8px 16px;
                    
                    border-radius: 10px;
                    box-sizing: border-box;
                      span{
                     
                       
                    
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%;
                    letter-spacing: 0.28px;
                    font-family: "Satoshi";
                    /* 21.6px */
                    // margin-bottom: 20px;
                    }
                   }
           
            }
    
            .calendar-cards{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 100%;
                column-gap: 20px;
    
            }
        }
    
    }
  }

// tableta mica sau telefon f maree
  @media screen and (min-width: 481px)  and (max-width: 768px) {
    .carouselsGrid-container {
        // background-color: rgb(73, 71, 71);
        position: relative;
        // padding: 60px 0 98px 0;
        margin-top: 75px;
        margin-left: var(--margin);
        margin-right: var(--margin);
    
        display: flex;
        flex-direction: column;
        row-gap: 40px;
    
        .vertical-bare-container {
            display: none;
            
        }
    
        .vertical-carousel-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 20px;
            .ver-carousel-title {
                display: flex;
                height: 40px;
                .bg{
                    background: rgba(255, 255, 255, 0.05);
                    color: rgba(255, 255, 255, 0.8);
                    justify-content: center;
                    align-items: center;
                    display: inline-flex;
        
                    padding: 8px 16px;
                    
                    border-radius: 10px;
                    box-sizing: border-box;
                      span{
                     
                       
                    
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%;
                    letter-spacing: 0.28px;
                    font-family: "Satoshi";
                    /* 21.6px */
                    // margin-bottom: 20px;
                    }
                   }
            }
            .vertical-carousel-cp{
                height: calc(100% - 40px);
            }
        }
    
        .horisontal-carousel-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 20px;
            .hor-carousel-title {
                display: flex;
                height: 40px;
                .bg{
                    background: rgba(255, 255, 255, 0.05);
                    color: rgba(255, 255, 255, 0.8);
                    justify-content: center;
                    align-items: center;
                    display: inline-flex;
        
                    padding: 8px 16px;
                    
                    border-radius: 10px;
                    box-sizing: border-box;
                      span{
                     
                       
                    
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%;
                    letter-spacing: 0.28px;
                    font-family: "Satoshi";
                    /* 21.6px */
                    // margin-bottom: 20px;
                    }
                   }
           
            }
    
            .calendar-cards{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-template-rows: 100%;
                column-gap: 20px;
    
            }
        }
    
    }
  }


  @media screen and (max-width: 480px) {
    .carouselsGrid-container {
        margin: 0 var(--margin);


        .vertical-bare-container {
            display: none;
       
        }

        .vertical-carousel-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 20px;
            margin-top: 20px;
            .ver-carousel-title {
                
                display: flex;
                height: 40px;
                .bg{
                    background: rgba(255, 255, 255, 0.05);
                    color: rgba(255, 255, 255, 0.8);
                    justify-content: center;
                    align-items: center;
                    display: inline-flex;
        
                    padding: 8px 16px;
                    
                    border-radius: 10px;
                    box-sizing: border-box;
                      span{
                     
                       
                    
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%;
                    letter-spacing: 0.28px;
                    font-family: "Satoshi";
                    /* 21.6px */
                    // margin-bottom: 20px;
                    }
                   }
            }
            .vertical-carousel-cp{
                height: calc(100% - 40px);
            }
        }


        .horisontal-carousel-container {
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 20px;
            .hor-carousel-title {
                display: flex;
                flex-direction: column;
                height: 40px;
                .bg{
                    width: fit-content;
                    background: rgba(255, 255, 255, 0.05);
                    color: rgba(255, 255, 255, 0.8);
                    justify-content: center;
                    align-items: center;
                    display: inline-flex;
        
                    padding: 8px 16px;
                    
                    border-radius: 10px;
                    box-sizing: border-box;
                      span{
                     
                       
                    
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 140%;
                    letter-spacing: 0.28px;
                    font-family: "Satoshi";
                    /* 21.6px */
                    // margin-bottom: 20px;
                    }
                   }
           
            }
    
            .calendar-cards{
                display: flex;
                flex-direction: column;
                row-gap: 20px;
                width: 100%;
                .calendarCard-container{
                    width: 100%;
                }
            }
        }
    }


  }