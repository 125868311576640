@media screen and (min-width: 1280px) {
  .vertical-carousel {
    height: 365px;
    border-radius: 15px;
  }
  .embla__container{
    height: calc(365px);
  }
  .embla__bullets{
    left: -20px;
  }
}

@media screen and (min-width: 769px)  and (max-width: 1279px) {
  .vertical-carousel {
    height: 365px;
    border-radius: 15px;
  }
  .embla__container{
    height: calc(365px);
  }
  .embla__bullets{
    left: -20px;
  }
}

@media screen and (min-width: 481px)  and (max-width: 768px) {
  .vertical-carousel {
    height: 450px;
    border-radius: 20px;
  }
  .embla__container{
    height: calc(450px);
  }
  .embla__bullets{
    left: -20px;
  }
}

@media screen and (max-width: 480px) {
  .vertical-carousel {
    height: 450px;
    border-radius: 20px;
  }
  .embla__container{
    height: calc(450px);
  }
  .embla__bullets{
    left: -15px;
    transform: scale(0.9);
  }
}

.vertical-carousel {
  --slide-spacing: 1rem;
  --slide-size: 100%;
  --slide-height: 22rem;
  /* padding: 1.6rem; */


  display: flex;
  /* margin-bottom: 100px; */

/* border: 3px solid rgba(255, 255, 255, 0.08); */
/* background: linear-gradient(90deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0.00) 100%), lightgray 0px -145.907px / 133.303% 269.088% no-repeat; */
  background-color: rgba(255, 255, 255, 0.04);
  /* box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 0.13);  */
  border: 3px solid rgba(255, 255, 255, 0.04);

}
.embla__viewport {
  display: inline;
  overflow: hidden;
  width: 100%;
  
  border-radius: 15px;
  margin-left: -8px;
  /* margin-bottom: 8px; */
  /* margin-top: -8px; */
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-x;
  flex-direction: column;
  border-radius: 15px;
  /* height: calc(var(--slide-spacing) + var(--slide-height)); */
  /* margin-top: calc(var(--slide-spacing) * -1); */
}
.embla__slide {
  flex: 0 0 calc(var(--slide-size) - 0px);
  min-height: 0;
  height: 100%;
  /* margin-top: 10px; */
  /* padding-top: var(--slide-spacing); */
  position: relative;
  border-radius: 15px;
}
.embla__slide__img {
  display: block;
  height: var(--slide-height);
  width: 100%;
  object-fit: cover;
  border-radius: 15px;
  cursor: pointer;
}
.embla__slide__number {
  width: 4.6rem;
  height: 4.6rem;
  z-index: 1;
  position: absolute;
  top: calc(var(--slide-spacing) + 0.6rem);
  right: 0.6rem;
  border-radius: 50%;
  background-color: rgba(var(--background-site-rgb-value), 0.85);
  line-height: 4.6rem;
  font-weight: 900;
  text-align: center;
  pointer-events: none;
}
.embla__slide__number > span {
  color: var(--brand-primary);
  background-image: linear-gradient(
    45deg,
    var(--brand-primary),
    var(--brand-secondary)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 1.6rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.embla__bullets {
  position: relative;
  /* transform: translateY(-50%); */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.embla__bullet {
  width: 8px;
  height: 16px;
  border-radius: 100px;
  background-color: white;
  margin-bottom: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  transition: 0.3s;
}

.embla__bullet.active {
  background-color: white;
  opacity: 0.2;
  width: 8px;
  height: 8px;
  transition: 0.3s;
}
