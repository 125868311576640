@media screen and (max-width: 480px) {
  .featured-card{
    .top-bar, .bot-bar{
      width: calc(100% - 30px * 2);
      padding: 30px;
      z-index: 999;
    }
  }
  .featured-card-modal{
    .top-bar{
      display: flex;
      flex-direction: column;
      align-items: flex-start !important;
      justify-content: center;
      gap: 20px;
    }
  }
}

@media screen and (min-width: 481px) and (max-width: 1279px) {
  .featured-card{
    .top-bar, .bot-bar{
      width: calc(100% - 20px * 2);
      padding: 20px;
      z-index: 999;
    }
  }
  .featured-card:hover{
    transition: 0.4s;
    box-shadow: inset 0 0 0 4px rgba(255, 255, 255, 0.7);
  }
}

@media screen and (min-width: 1280px) {
    .featured-card{
      .top-bar, .bot-bar{
        width: calc(100% - 30px * 2);
        padding: 30px;
        z-index: 999;
      }
  }
  .featured-card:hover{
    transition: 0.4s;
  }
}



.featured-card{
  transition: 0.4s;
  cursor: pointer;
  //
  position: relative;
  width: 100%;
  height: 380px;
  //
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  //
  background-size: cover;
  overflow: hidden;
  //
  border-radius: 15px;
  box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 0.08);
  //
  .shadow{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 15px;
  }
  //
  .top-bar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 2;
    width: 100%;
  }
  .top-bar-closed{
    .card-actions{
      width: 100%;
    }
  }
  .bot-bar{
    //
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    //
    .text{
      margin-left: 5px;
      position: relative;
      .title, .location{
        text-overflow: ellipsis;
        margin: 0;
        color: #FFF;
        font-family: 'Satoshi';
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 135%; /* 21.6px */
      }
      .location{
        opacity: 0.7;
      }
    }
    .add-button:hover{
      background-color: white;
      transition: 0.4s;
    }
    .add-button{
      transition: 0.4s;
      height: 44px;
      cursor: pointer;
      position: relative;
      width: fit-content;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      padding: 10px 16px;
      border-radius: 7px;
      background: rgba(255, 255, 255, 0.8);
      backdrop-filter: blur(100px);
      border: none;
      .icon{
        display: flex;
        align-items: flex-end;
        justify-content: center;
      }
      p{
        margin: 0;
        font-family: 'Satoshi';
        color: #141414;
        font-size: 15px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-transform: capitalize;
      }
      .separator{
        width: 2px;
        height: 12px;
        border-radius: 46px;
        background: rgba(0, 0, 0, 0.20);
      }
    }
  }
}