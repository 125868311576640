.blurred-background{
  position: absolute;
  left: 0;
  top: 0;
  //
  width: 100%;
  height: 1000px;

  overflow: hidden;
  //
  img{
    transform: scale(1.1);
    position: absolute;
    left: 0;
    z-index: -2;
    //
    width: 100vw;
    height: 600px;
    object-fit: cover;
    filter: blur(50px);
    -webkit-filter: blur(50px);
  }
  .shadow{
    position: absolute;
    left: 0;
    top: 0;
    //
    width: 100%;
    height: 800px;
    z-index: -1;
    //
    opacity: 0.5;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.5) 50%, rgba(0,0,0,0.5) 75%);
  }
}