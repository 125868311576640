
.cityPageStacked-card {
  // border: 3px solid rgba(255, 255, 255, 0.08);
  box-shadow:inset 0 0 0 3px rgba(255, 255, 255, 0.08);
  position: relative;
  .shadow{
      position: absolute;
      background-color: rgba(0, 0, 0, 0.124);
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      border-radius: 15px;
  }
}
@media screen and (min-width: 1280px) {
    .cityPageStacked-card {
      height: 365px;
      padding: 40px 0 40px 40px;
      border-radius: 15px;
      //
      .buttons {
        display: flex;
        column-gap: 15px;
        //
        .button {
          padding: 13px 20px;
          height: 44px;
          cursor: pointer;
        }
      }
    }
  }
  
  @media screen and (min-width: 769px)  and (max-width: 1279px) {
    .cityPageStacked-card {
      height: 365px;
      padding: 40px 0 40px 40px;
      border-radius: 15px;
      //
      .buttons {
        display: flex;
        column-gap: 15px;
        //
        .button {
          padding: 13px 20px;
          height: 44px;
          cursor: pointer;
        }
      }
    }
  }
  
  @media screen and (min-width: 481px)  and (max-width: 768px){
    .cityPageStacked-card {
      height: 450px;
      padding: 20px 0 20px 20px;
      border-radius: 20px;
      //
      .buttons {
        width: calc(100% - 20px);
        height: calc(50px * 2 + 10px);
        display: grid;
        grid-template-columns: [start] 100% [end];
        grid-template-rows: [start] 50px  [end];
        column-gap: 10px;
        row-gap: 10px;
        //
        .button {
          width: 100%;
          height: 100%;
        }
        .scopri {
          grid-column-start: start;
          grid-column-end: mid;
          grid-row-start: start;
          grid-row-end: mid;
        }
        .share-buttons {
          grid-column-start: mid;
          grid-column-end: end;
          grid-row-start: start;
          grid-row-end: mid;
        }
        .ora {
          margin-top: 50px;
          grid-column-start: start;
          grid-column-end: end;
          grid-row-start: mid;
          grid-row-end: end;
          
        }
      }
    }
  }
  
  @media screen and (max-width: 480px) {
    .cityPageStacked-card {
      height: 450px;
      padding: 20px 0 20px 20px;
      border-radius: 20px;
      //
      .buttons {
        width: calc(100% - 20px);
        height: calc(50px * 2 + 10px);
        display: grid;
        grid-template-columns: [start] 100% [end];
        grid-template-rows: [start] 50px  [end];
        column-gap: 10px;
        row-gap: 10px;
        //
        .button {
          width: 100%;
          height: 100%;
        }
        .scopri {
          grid-column-start: start;
          grid-column-end: mid;
          grid-row-start: start;
          grid-row-end: mid;
        }
        .share-buttons {
          grid-column-start: mid;
          grid-column-end: end;
          grid-row-start: start;
          grid-row-end: mid;
        }
        .ora {
          margin-top: 50px;
          grid-column-start: start;
          grid-column-end: end;
         
          
        }
      }
    }
  }
  
  .cityPageStacked-card {
    position: relative;
    width: 100%;
    //
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    //
    background: linear-gradient(
      270deg,
      rgba(0, 0, 0, 0) 35.84%,
      rgba(0, 0, 0, 0.54) 77.16%
    );
    background-position: 30% 30%;
    background-size: cover;
    object-fit: cover;
    //
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
  
    .blur-top {
      // display: none;
      // position: absolute;
      // z-index: 1;
      // width: 300px;
      // height: 100%;
  
      // left: -3px;
      // top: -3px;
      // border-radius: 15px 15px 0px 0px;
  
      // background: #ffffff03;
      // // filter: blur(31.5px);
      // backdrop-filter: blur(2px);
    }
  
    .content {
      z-index: 22;
      // margin-bottom: 60px;
  
      p {
        margin: 0;
      }
      .title {
        font-style: normal;
        font-weight: 700;      font-size: 30px;
        line-height: 130%;
        /* identical to box height, or 33px */
  
        letter-spacing: -0.02em;
        // text-transform: uppercase;
  
        color: #ffffff;
        margin-bottom: 5px;
      }
      .subtitle {
        color: #ffffff54;
        font-weight: 700;
        font-size: 16px;
        line-height: 110%;
        /* identical to box height, or 18px */
        color: rgba(255, 255, 255, 0.70);
  
  
        letter-spacing: 0.02em;
      }
    }
  
    .mid-row {
      z-index: 23;
      display: flex;
      align-items: center;
      column-gap: 10px;
      .people {
        display: flex;
  
        .circle-avatar {
          background-image: url("https://images.unsplash.com/photo-1546838739-81394dda9a7b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80");
          border-radius: 50%;
          width: 40px;
          height: 40px;
          position: relative;
          z-index: 3;
        }
        .svg1 {
          z-index: 2;
          margin-left: -4px;
        }
        .svg2 {
          z-index: 1;
          margin-left: -5px;
        }
        svg {
          width: 25px;
          height: 40px;
          image {
            background-size: cover;
            background-position: left top;
          }
        }
      }
      .number {
        background-color: #0085ff;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 20px;
        font-size: 13px;
        column-gap: 1px;
        border-radius: 81px;
        padding: 2px 9px 2px 7px;
        color: white;
        font-weight: 800;
        span {
          margin-left: 2px;
        }
        svg {
          margin-bottom: 1px;
          transform: scale(0.9);
          path {
            strokewidth: 1.6px;
          }
        }
      }
    }
    .buttons {
      z-index: 24;
      color: white;
      .ora {
        color: #292d35;
        display: flex;
        box-sizing: border-box;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 11px 20px;
        gap: 9px;
        background: rgba(255, 255, 255, 0.95);
        backdrop-filter: blur(22.5px);
        font-weight: 800;
        border-radius: 10px;
        .icon {
          width: 16px;
          height: 16px;
          // margin-left: 6px;
  
          svg {
              height: 100%;
              width: 100%;
          }
      }
      }
      .scopri {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 25px 12px 20px;
        gap: 10px;
        background: rgba(31, 34, 41, 0.6);
        border: 2px solid rgba(255, 255, 255, 0.03);
        backdrop-filter: blur(65.5px);
        /* Note: backdrop-filter has minimal browser support */
  
        border-radius: 10px;
        span {
          opacity: 0.8;
          font-weight: 500;
        }
        svg {
          transform: scale(1.05);
        }
      }
      .share-buttons {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 12px 20px;
        gap: 20px;
        background: rgba(31, 34, 41, 0.6);
        border: 2px solid rgba(255, 255, 255, 0.03);
        backdrop-filter: blur(65.5px);
        /* Note: backdrop-filter has minimal browser support */
  
        border-radius: 10px;
        svg {
          cursor: pointer;
          color: white;
        }
  
        border-radius: 7px;
        .hor-line {
          width: 2px;
          height: 12px;
          background: rgba(255, 255, 255, 0.05);
          border-radius: 46px;
          // transform: rotate(90deg);
        }
      }
    }
  }
  