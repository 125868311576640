.chat-overlay{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  backdrop-filter: blur(140px);
  z-index: 2000;
}

.mini-chat{
  transition: 0.2s;
  svg{
    transition: 0.2s;
    path{
      transition: 0.2s;
    }
  }
  .tag{
    transition: 0.2s;
  }
}

.mini-chat-closed {
  cursor: pointer;
  position: fixed;
  z-index: 2001;
  box-sizing: border-box;
  border-radius: 20px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), rgba(25, 29, 35, 0.50);
  backdrop-filter: blur(50px);
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 100px;
  right: calc(var(--margin) - 30px - 10px);
  padding: 5px;
  .input-container-closed{
    background-color: #191D23;
    width: 50px;
    height: 50px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg{
      transform: scale(1.25);
    }
    p{
      font-weight: 500;
      color: rgba(255, 255, 255, 0.8);
      margin: 0;
    }
    .tag{
      position: absolute;
      left: -160px;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), rgba(25, 29, 35, 0.50);
      padding: 10px 16px;
      border-radius: 100px;
      backdrop-filter: blur(140px);
      opacity: 0;
    }
  }
  .control-buttons{
    display: none;
  }
}

.mini-chat-hover {
  cursor: pointer;
  position: fixed;
  z-index: 2001;
  box-sizing: border-box;
  border-radius: 20px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), rgba(25, 29, 35, 0.50);
  backdrop-filter: blur(50px);
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 98px;
  right: calc(var(--margin) - 30px - 10px - 2px);
  padding: 7px;
  .input-container-hover{
    background-color: #191D23;
    width: fit-content;
    height: 50px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    box-sizing: border-box;
    gap: 15px;
    padding: 15px;
    svg{
      transform: scale(0.8);
      border-radius: 100px;
      path{
        stroke-width: 5;
      }
    }
    p{
      font-weight: 500;
      color: rgba(255, 255, 255, 0.8);
      margin: 0;
    }
    .tag{
      position: absolute;
      left: -170px;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), rgba(25, 29, 35, 0.50);
      padding: 10px 16px;
      border-radius: 100px;
      backdrop-filter: blur(140px);
      opacity: 1;
    }
  }
  .control-buttons{
    display: none;
  }
}

.mini-chat-open {
  // cursor: pointer;
  position: fixed;
  z-index: 2001;
  box-sizing: border-box;
  border-radius: 20px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.10) 100%), rgba(25, 29, 35, 0.50);
  backdrop-filter: blur(50px);
  display: flex;
  height: calc(100dvh - 2 * 100px);
  max-height: 600px;
  width: 400px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 7px;
  bottom: 98px;
  right: calc(var(--margin) - 30px - 10px - 2px);
  padding: 7px;
  .messages{
    overflow: scroll;
    background-color: #191D23;
    width: 100%;
    height: 100%;
    border-radius: 15px;
    box-sizing: border-box;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .message{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      width: 100%;
      gap: 20px;
      p{
        margin: 0;
      }
      .sent-container{
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        gap: 16px;
        width: 100%;
        .chat-avatar-default{
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          svg{
            width: 22px;
            opacity: 1;
          }
        }
        .avatar{
          width: 41.5px;
          height: 41.5px;
          border-radius: 100px;
          margin-top: 2px;
          // background-color: white;
        }
        p{
          padding: 10px 16px;
          background-color: #0085FF;
          border-radius: 10px;
          font-size: 16px;
          font-weight: 500;
          color: #fff;
          max-width: calc(100% - 16px - 41.5px - 40px);
        }
      }
      .received-container{
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 16px;
        .avatar{
          margin-top: px;
          svg{
            opacity: 1;
          }
        }
        .avatar{
          width: 41.5px;
          height: 41.5px;
          border-radius: 100px;
          background-color: white;
        }
        .chat-avatar{
          background-color: black;
          display: flex;
          align-items:center;
          justify-content: center;
        }
        .received-mkd-container{
          width: calc(100% - 16px - 42px);
          display: flex;
          flex-direction: column;
          align-items:flex-start;
          justify-content: flex-start;
          gap: 20px;
          padding: 10px 16px;
          background-color: rgba(242, 244, 249, 0.05);
          border-radius: 10px;
          div, span, ol{
            display: flex;
            flex-direction: column;
            align-items:flex-start;
            justify-content: flex-start;
            gap: 20px;
            width: 100%;
          }
          p{
            font-size: 16px;
            font-weight: 500;
            color: #fff;
            // max-width: calc(100% - 16px - 41.5px - 40px);
          }
          li{
            font-size: 16px;
            font-weight: 400;
            color: #fff;
            // max-width: calc(100% - 16px - 41.5px - 40px);
          }
        }
       
      }
    }
  }
  .input-container-open{
    position: relative;
    background-color: #191D23;
    width: 100%;
    height: 44px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    box-sizing: border-box;
    gap: 15px;
    padding: 0 15px;
    /* Change the white to any color */
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
        -webkit-background-clip: text;
        -webkit-text-fill-color: #ffffff;
        transition: background-color 5000s ease-in-out 0s;
        box-shadow: inset 0 0 20px 20px #23232329;
    }
    input:focus{
      outline: none;
    }
    input{
      border: none;
      background: none;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
      padding: 0 16px;
      padding-right: 80px;
      padding-left: 0;
      border-radius: 10px;
      color: white;
      font-size: 16px;
      font-family: "Satoshi";
      font-weight: 500;
      // background-color: red;
    }
    button:hover{
      background-color: rgba(255, 255, 255, 0.05);
      p{
        opacity: 0.9;
      }
    }
    button{
      transition: 0.3s;
      cursor: pointer;
      position: absolute;
      right: 5px;
      // top: 5px;
      height: 30px;
      border-radius: 7px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      padding: 0 10px;
      background-color: transparent;
      svg{
        color: white;
        width: 14px;
        strokeWidth: 2px;
      }
      p{
        transition: 0.3s;
        margin: 0;
        font-size: 14px;
        color: white;
        font-weight: 500;
        opacity: 0.5;
      }
    }
    .send_true{
      pointer-events: none;
      svg{
        opacity: 0.5;
      }
    }
  }
  .control-buttons{
    position: absolute;
    right: -40px;
    top: 20px;
    display: flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    button{
      width: 30px;
      height: 30px;
      border-radius: 100px;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      pointer-events: all;
    }
    .close{
      background-color: #FF003D;
      color: white;
      svg{
        width: 20px;
      }
    }
    .expand{
      background-color: white;
      color: rgba(25, 29, 35, 0.8);
      svg{
        width: 20px;
      }
    }
  }
}




@media screen and (max-width: 580px) {
  .mini-chat-closed{
    right: calc(var(--margin) + 10px);
    bottom: 80px;
  }
}


@media screen and (max-width: 1279px) and (min-width: 768px) {
  .mini-chat-open {
    right: calc(var(--margin) - 30px + 30px);
  
  }

}