@media screen and (max-width: 480px) {
  .content-card{
    gap: 10px;
    .card-image, .card-details{
      border-radius: 20px;
    }
    .card-image{
      height: 200px;
    }
    .card-details{
      height: 150px;
    }

  }
}

@media screen and (min-width: 481px) {
  .content-card{
    gap: 20px;
    .card-image, .card-details{
      border-radius: 15px;
    }
  }
}

.content-card{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  //
  width: 100%;
  //
  font-family: 'Satoshi';
  //
  .card-image, .card-details{
    width: calc(100% - 20px * 2);
    padding: 20px;
  }
  .card-image{
    width: 100%;
    cursor: pointer;
    aspect-ratio: 1.7;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    //
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
  .card-details{
    width: 100%;
    // height: calc(150px - 20px * 2);
    //
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: space-between;
    gap: 20px;
    .title-container{
      width: 100%;
      padding: 0 4px;
      .title{
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%; /* 19.2px */
        margin: 0;
        max-width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1; /* Limit content to two lines */
        -webkit-box-orient: vertical;
        white-space: normal;
      }
    }
    .sub{
      font-size: 15px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin: 0;
      
        //ellipsis
      max-width: 400px; /* Set your desired maximum width */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit content to two lines */
  -webkit-box-orient: vertical;
  white-space: normal;
    }
    .button:hover{
      opacity: 0.8;
    }
    .button{
      font-family: 'Satoshi';
      cursor: pointer;
      display: flex;
      padding: 8px 13px 9px 13px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 10px;
      background: #0085FF;
      border: none;
      color: #FFF;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%; /* 21px */
      height: 38px;
      svg{
        width: 15px;
        margin-top: 1px;
        opacity: 0.7;
      }
      .hours{
        opacity: 0.7;
        text-transform: capitalize;
      }
    }
    .separator{
      width: 2px;
      height: 12px;
      border-radius: 46px;
      background: rgba(255, 255, 255, 0.20);
    }
    .button-activity{
      .added{
        display:flex;
        align-items: center;
        justify-content: center;
        gap: 4px;
      }
    }
    .button-added-true{
      background: #242930;
      pointer-events: none;
    }
  }
}

.content-card-dark{
  .card-image{
    box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 0.08);  
  }
  .card-details{
    box-shadow: inset 0 0 0 3px var(--Midnight-Border-100, #242930);
    background: #15181E;
    .title-container{
      .title{
        color: rgba(255, 255, 255, 0.90);
      }
      .sub{
        color: rgba(139, 160, 181, 0.80);
      }
    }
  }
}

.content-card-light{
  .card-image{
    background-color: #efefef;
    box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 0.3);
  }
  .card-details{
    box-shadow: inset 0 0 0 3px rgba(241, 245, 249, 0.80);
    background: #FCFCFC;
    .title-container{
      .title{
        color: #15181E;
      }
      .sub{
        color: #8BA0B5;
      }
    }
  }
}