@media screen and (max-width: 768px) {
    .heroGrid-container {
        grid-template-rows: auto auto;
        row-gap: 66px;
        .statistics-block {
            padding: 20px;
            border-radius: 20px;
            gap: 15px;
            .avatar-row{
                column-gap: 20px;
                .avatar{
                    width: 80px;
                    height: 80px;
                }
            }
            .statistics-row{
                div{
                    padding: 5px;
                    .number{
                        font-size: 20px;
                    }
                }
            }
        }
        .qr-block{
            padding: 20px;
            border-radius: 20px;
            gap: 20px;
            .first-row {
                row-gap: 20px;
                flex-direction: column;
                .qr-code{
                    width: calc(100% - 2 * 2px);
                    aspect-ratio: 1;
                    overflow: hidden;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
                .content{
                    margin-left: 5px;
                }
            }
        }
    }  
}

@media screen and (min-width: 769px) and (max-width: 1279px) {
    .heroGrid-container{
        // grid-template-columns: [start] calc(var(--col) * 5 + 4 * 20px) [col2] calc(var(--col) * 7 + 6 * 20px) [end];
        grid-template-rows: 275px 275px;
        row-gap: 66px; 
        .statistics-block {
            padding: 40px;
            border-radius: 15px;
            .avatar-row{
                column-gap: 40px;
                .avatar{
                    width: 103px;
                    height: 103px;
                }
            }
            .statistics-row{
                div{
                    .number{
                        font-size: 25px;
                    }
                }
            }
        }
        .qr-block{
            padding: 40px;
            border-radius: 15px;
            .first-row {
                column-gap: 40px;
                .qr-code{
                    width: 103px;
                    height: 103px;
                    overflow: hidden;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}


@media screen and (min-width: 1280px) {
    .heroGrid-container{
        grid-template-columns: [start] calc(var(--col) * 5 + 4 * 20px) [col2] calc(var(--col) * 7 + 6 * 20px) [end];
        grid-template-rows: 275px;
        column-gap: 20px; 
        .statistics-block {
            padding: 30px;
            border-radius: 15px;
            .avatar-row{
                column-gap: 20px;
                .avatar{
                    width: 90px;
                    height: 90px;
                    object-fit: cover;
                    background-position: center center;
                    background-repeat: no-repeat;
                }
                .content{
                    max-width: calc(100% - 103px - 40px);
                    p{
                        overflow: hidden;
                        white-space: nowrap;
                    }
                }
            }
            .statistics-row{
                div{
                    .number{
                        font-size: 25px;
                    }
                }
            }
        }
        .qr-block{
            padding: 30px;
            border-radius: 15px;
            .first-row {
                column-gap: 40px;
                .qr-code{
                    width: 103px;
                    height: 103px;
                    overflow: hidden;
                    img{
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}

.heroGrid-container {
    display: grid;  
    font-family: "Satoshi";
    .statistics-block {
        position: relative;
        box-shadow: inset 0 0 0 3px rgba(255, 255, 255, 0.10);
        background: rgba(0, 0, 0, 0.14);
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        position: relative;
        box-sizing: border-box;
        object-fit: cover;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        .shadow {
            box-sizing: border-box;
            position: absolute;
            height: 100%;
            width: 100%;
            z-index: 1;
            left: 0;
            top: 0;
            border-radius: 10px;;
            border-radius: 15px;
            border: 3px solid rgba(255, 255, 255, 0.08);
            background-color: rgba(0, 0, 0, 0.3);
        }
        .avatar-row {
            display: flex;
            z-index: 99;
            .avatar {
                border-radius: 10px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center center;
            }

            .content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                p {
                    margin: 0;
                }

                .name {
                    color: #FFF;
                    font-family: "Satoshi";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                .description {
                    color: #FFFFFF80;
                    font-family: "Satoshi";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 145%;
                    /* 23.2px */
                    letter-spacing: 0.48px;
                }
            }
        }

        .statistics-row {
            display: flex;
            justify-content: space-between;
            color: white;
            position: relative;
            z-index: 9;

            div {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .number {
                    color: #FFF;
                    text-align: center;


                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                .type {
                    color: rgba(255, 255, 255, 0.50);
                    text-align: center;

                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }
            }
        }

    }

    .qr-block {   
        box-shadow: inset 0 0 0 3px #242930;
        background: #15181E;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        .first-row {
            display: flex;
            .qr-code {
                border-radius: 10px;
                border: 2px solid rgba(0, 0, 0, 0.16);
                background: #FFF;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                img{
                    transform: scale(1.1)
                }
            }

            .content {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                p {
                    margin: 0;
                }

                .title {
                    color: #FFF;
                    font-family: "Satoshi";
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }

                .description {
                    color: #FFFFFF80;
                    font-family: "Satoshi";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 145%;
                    /* 23.2px */
                    letter-spacing: 0.48px;
                }
            }
        }

        .button {
            border-radius: 10px;
            border: 2px solid rgba(41, 45, 53, 0.08);
            background: #0085FF;
            width: fit-content;

            display: flex;
            padding: 12px 20px 13px 20px;
            align-items: center;
            gap: 10px;

            .button-text {
                color: #FFF;
                font-family: "Satoshi";
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;

            }
        }

    }
}
.impostazioni-button{
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: -55px;
    //
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    //
    width: fit-content;
    padding: 10px 18px;
    padding-top: 8px;
    //
    border-radius: 42px;
    border: 2px solid var(--Midnight-Border-100, #242930);
    background: #15181E;

    cursor: pointer;
    .button-text{
        color: rgba(255, 255, 255, 0.50);
        font-family: "Satoshi" ;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 0.32px;
    }
}

.heroGrid-container{
    .shadow{
        backdrop-filter: blur(50px);
    }
}

.avatar{
    overflow: hidden;
    position: relative;
    outline: 3px solid rgba(255, 255, 255, 0.2);
    cursor: pointer;
    img{
        top: 0;
        left: 0;
        position: absolute;
        transition: 0.3s;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
    input{
        display: none;
    }
    svg{
        transition: 0.3s;
        position: relative;
        width: 30px;
        color: white;
        opacity: 0
    }
}

.avatar:hover{
    img{
        transform: scale(1.2);
        filter: blur(8px);
        -webkit-filter: blur(8px);
    }
    svg{
        opacity: 0.8;
    }
}