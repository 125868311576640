.share-modal-shadow{
  position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        background: rgba(255, 255, 255, 0.01);
        backdrop-filter: blur(140px);
        z-index: 1001; /* Ensure the clipRule is above other elements */
}

.share-modal{
  width: 400px;
  max-width: calc(100% - 40px - 40px);
  height: fit-content;
  background-color: white;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  border-radius: 15px;
  border: 2px solid rgba(255, 255, 255, 0.03);
  border-left: transparent;
  background: rgba(21, 24, 30, 0.6);
  z-index: 1002;
  padding: 30px;
  display: flex;
  gap: 40px;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  .topbar{
    // width: calc(100% - 2 * var(--margin) - 40px * 2);
    width: calc(100% );
    display: flex;
    align-items: center;
    justify-content: flex-end;
    box-sizing: border-box;
    .close{
      // transition: 0.4s;
      cursor: pointer;
      display: flex;
      width: 40px;
      height: 40px;
      justify-content: center;
      align-items: center;
      gap: 20px;
      flex-shrink: 0;
      border-radius: 52px;
      border: 2px solid rgba(255, 255, 255, 0.03);
      background: rgba(31, 34, 41, 0.60);
      backdrop-filter: blur(65.5px);
      svg{
        color: white;
        width: 20px;
        strokeWidth: 2px;
        opacity: 0.9;
      }
    }
  }
  .buttons{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    .share-button:hover{
      background-color: rgba(21, 24, 30, 0.4);
      p{
        opacity: 1;
      }
    }
    .share-button{
      transition: 0.3s;
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      background-color: rgba(21, 24, 30, 0.6);
      border-radius: 10px;
      padding: 10px 16px;
      gap: 10px;
      color: white;
      font-weight: 500;
      cursor: pointer;
      svg{
        width: 20px;
        height: 20px;
        color: white;
        opacity: 0.5;
      }
      p{
        opacity: 0.9;
      }
    }
  }

}