.navbar-container {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999999;
  //
  width: 100vw;
  height: calc(48px + 40px);
  //
  font-family: "Satoshi";
  color: white;
  //
  .navbar {
    position: absolute;
    z-index: 999999999; /* Ensure the overlay is above other elements */
    top: 20px;
    left: 0;
    right: 0;
    margin: auto;
    //
    display: flex;
    justify-content: space-between;
    align-items: center;
    //
    width: calc(100% - 2 * var(--margin) - 20px);
    //
    .overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100vh;
      background: rgba(255, 255, 255, 0.01);
      backdrop-filter: blur(140px);
      z-index: 99999999; /* Ensure the overlay is above other elements */
      // pointer-events: none; /* Allow clicks to pass through to elements below */
    
      
    }

    .links{
      .icon-links{
        border-radius: 12px !important;
        .link-container{
          width: fit-content;
          .icon-and-text{
            display: flex;
            gap: 10px;
            svg{
              width: 18px;
              height: 18px;
              // background-color: red;
            }
          }
        }
      }
    }
    .home {
      display: flex;
      align-items: center;
      justify-content: center;
      //
      width: fit-content;
      white-space: nowrap;
      height: 48px;
      // box-sizing: border-box;
      a{
        height: 100%;
        backdrop-filter: blur(100px);
        border-radius: 12px 0px 0px 12px;
      }
      .logo {
        width: 44px;
        height: 100%;
        //
        display: flex;
        justify-content: center;
        align-items: center;
        //
        border-radius: 12px 0px 0px 12px;
        border: 2px solid rgba(255, 255, 255, 0.03);
        background: rgba(21, 24, 30, 0.6);
        box-sizing: border-box;
        svg {
          width: 19px;
          height: 19px;
          cursor: pointer;
        }
      }
      .border-left-0px{
        border-left: 0px!important;
      }
      .home-link {
        backdrop-filter: blur(100px);
        display: flex;
        justify-content: center;
        align-items: center;
        //
        padding: 9px 23px 11px 17px;
        height: 48px;
        box-sizing: border-box;
        //
        border-radius: 0px 12px 12px 0px;
        border: 2px solid rgba(255, 255, 255, 0.03);
        border-left: transparent;
        background: rgba(21, 24, 30, 0.6);
        div {
          font-size: 16px;
          font-weight: 500;

        }
      }
    }
    .login {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 10px;
      margin: 0;
      width: fit-content;
    }
    .hamburger{
      width: 48px !important;
      height: 48px !important;
      border-radius: 12px !important;
    }
    .account-link{
      .account-icon:hover{
        outline: 2px solid #0085ff;
      }
     .account-icon{
      height: 44px;
      width: 44px;
      outline: 2px solid rgba(255, 255, 255, 0.2);
      box-sizing: border-box;
      border-radius: 12px;
      position: relative;
      background-position: center center;
      background-size: cover;
      transition: 0.3s;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      svg{
        width: 20px;
      }
      .account-likes{
        position: absolute;
        border-radius: 100px;
        width: 24px;
        height: 24px;
        background-color: #ff4259;
        display: flex;
        align-items: center;
        justify-content: center;
        right: -8px;
        top: -8px;
        svg{
          width: 15px;
          height: 15px;
        }
      }
     }
    }
  }
}



//phone
@media screen and (max-width: 580px) {
  .navbar-container {
    //not required on mobile
    .navbar {
      .home{
        z-index: 2; /* Ensure the overlay is above other elements */
      }
      .links,
      .search-bar-half {
        display: none;
      }
      .login {
        z-index: 2; /* Ensure the overlay is above other elements */


        .mio-spazio-link {
          .mio-spazio {
            cursor: pointer;
            display: flex;
            height: 44px;
            margin-right: 0px;

            .favs {
              cursor: pointer;

              color: #292d35;
              font-size: 16px;
              font-family: "Satoshi";
              font-weight: 700;
              border-radius: 10px;
              background: var(--white-90, rgba(255, 255, 255, 0.9));
              backdrop-filter: blur(22.5px);

              width: 44px;
              height: 100%;
              box-sizing: border-box;
              border-radius: 10px 0px 0px 10px;
              box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.13);
              /* Adjust the values as needed */
              display: flex;
              align-items: center;
              justify-content: center;

              display: none;
            }

            .image {
              width: 44px;
              height: 100%;
              background-image: url("https://images.unsplash.com/photo-1546838739-81394dda9a7b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80");
              background-position: center center;
              background-repeat: no-repeat;
              background-size: cover;
              border-radius:  10px ;
              // box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.13);
              /* Adjust the values as needed */
            }
            .account-svg{
              display: flex;
              width: 44px;
              height: 44px;
              padding: 2px 5px;
              justify-content: center;
              align-items: center;
              gap: 10px;
              border: none;
              box-sizing: border-box;
              border-radius:  10px;
              background-color: rgb(255, 255, 255);
              svg{
                path{
                  stroke: rgb(0, 133, 255);
                  strokeWidth: 1.5;
                }
              }
      
      
            }

          }

        }


        .login-link{
          cursor: pointer;
          .login{
            display: flex;
            width: 44px;
            height: 44px;
            justify-content: center;
            align-items: center;

            border-radius: 10px;
            box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.10);
            // border: 2px solid rgba(0, 0, 0, 0.10);
            background-color: white;
            box-sizing: border-box;
            // margin-right: 15px;
          }
        }
        .favs {
          display: none;
        }
        .image {
          width: 44px;
          height: 44px;
          border-radius: 10px;
          border: 2px solid rgba(255, 255, 255, 0.08);
          background-image: url("https://images.unsplash.com/photo-1546838739-81394dda9a7b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80");
        }
        .hamburger {
          display: flex;
          width: 44px;
          height: 44px;
          justify-content: center;
          align-items: center;
          gap: 7px;
          border-radius: 10px;
          border: 2px solid rgba(255, 255, 255, 0.03);
          background: rgba(31, 34, 41, 0.6);
        }
      }

      .overlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(255, 255, 255, 0.01);
        backdrop-filter: blur(140px);
        z-index: 1; /* Ensure the overlay is above other elements */
        pointer-events: none; /* Allow clicks to pass through to elements below */

        
      }
    }
  }
}

@media screen and (min-width: 561px) and (max-width: 1279px) {
  .navbar-container {
    //not required on mobile
    .navbar {
      .home{
        z-index: 999999999; /* Ensure the overlay is above other elements */
      }
      .links,
      .search-bar-half {
        display: none;
      }

      .links{
        display: none !important;
      }

      .login {
        z-index: 999999999; /* Ensure the overlay is above other elements */
        .mio-spazio-link {
          .mio-spazio {
            cursor: pointer;
            display: flex;
            height: 44px;
            // margin-right: 20px;

            .favs {
              cursor: pointer;

              color: #292d35;
              font-size: 16px;
              font-family: "Satoshi";
              font-weight: 700;
              border-radius: 10px;
              background: var(--white-90, rgba(255, 255, 255, 0.9));
              backdrop-filter: blur(22.5px);

              width: 44px;
              height: 100%;
              box-sizing: border-box;
              border-radius: 10px 0px 0px 10px;
              box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.13);
              /* Adjust the values as needed */
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .image {
              box-sizing: border-box;
              width: 44px;
              height: 100%;
              background-image: url("https://images.unsplash.com/photo-1546838739-81394dda9a7b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80");
              background-position: center center;
              background-repeat: no-repeat;
              background-size: cover;
              border-radius: 0px 10px 10px 0px;
              // box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.13);
              /* Adjust the values as needed */
            }
          }

        }
        .login-link{
          cursor: pointer;
          .login{
            display: flex;
            width: 44px;
            height: 44px;
            justify-content: center;
            align-items: center;

            border-radius: 10px;
            box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.10);
            // border: 2px solid rgba(0, 0, 0, 0.10);
            background-color: white;
            box-sizing: border-box;
            // margin-right: 15px;
          }
        }
        .favs {
          display: none;
        }
        .image {
          width: 44px;
          height: 44px;
          border-radius: 10px;
          border: 2px solid rgba(255, 255, 255, 0.08);
          background-image: url("https://images.unsplash.com/photo-1546838739-81394dda9a7b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80");
        }
        .hamburger {
          display: flex;
          width: 44px;
          height: 44px;
          justify-content: center;
          align-items: center;
          gap: 7px;
          border-radius: 10px;
          border: 2px solid rgba(255, 255, 255, 0.03);
          background: rgba(31, 34, 41, 0.6);
        }
      }


    }

  }
}








@media screen and (min-width: 1280px) {
  .navbar-container {
    .navbar {
      .home {
        z-index: 999999999; /* Ensure the overlay is above other elements */
        .vertical-line {
          width: 2px;
          height: 12px;
          border-radius: 46px;
          background: rgba(217, 217, 217, 0.22);
          margin: 0 20px;
        }
      }
      .links {
        z-index: 999999999; /* Ensure the overlay is above other elements */
        width: fit-content;
        white-space: nowrap;
        grid-column-start: home;
        grid-column-end: links;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;


        .icon-links{
          display: flex;

          border-radius: 10px;
          border: 2px solid rgba(255, 255, 255, 0.03);
          background: rgba(21, 24, 30, 0.60);
          backdrop-filter: blur(100px);
          padding: 9px 18px 11px 18px;

          .link-container {
            display: flex;
            align-items: center;
            a{
              display: flex;
              align-items: center;
            }
            .icon-and-text{
              cursor: pointer;
              transition: 0.2s;
            }
            .icon-and-text:hover{
              opacity: 0.5;
              transition: 0.2s;
            }
            .vertical-line-links{
           
                width: 2px;
                height: 12px;
                border-radius: 46px;
                background: rgba(217, 217, 217, 0.22);
                margin: 0 18px;
              
            }
          }
        }

        .link3 {
          margin-right: 0;
        }
        .vertical-line {
          width: 2px;
          height: 12px;
          border-radius: 46px;
          background: rgba(217, 217, 217, 0.22);
          margin: 0 20px;
        }
        .mr-0 {
          margin-right: 0;
        }
      }

      .search-bar-half {
        z-index: 999999999; /* Ensure the overlay is above other elements */
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: white;
        position: relative;
        height: 48px;
        .search-container input:focus {
          border: 2px solid rgba(255, 255, 255, 0.787);
          outline: none;
          /* Remove the outline on focus (optional) */
        }
        .search-container {
          width: 100%;
          input {
            box-sizing: border-box;
            width: 100%;
            // border-radius: 10px;
            border-radius: 58px;
            border: 2px solid rgba(255, 255, 255, 0.03);
          background: rgba(21, 24, 30, 0.60);
          backdrop-filter: blur(100px);
            padding: 12px 20px 12px 38px;
            margin-left: 2px;
            color: #999;
          }
          .search-icon {
            position: absolute;
            left: 18px;
            bottom: -6px;
            /* Adjust as needed */
            top: calc(50% + 3px);
            transform: translateY(-50%);
            pointer-events: none;
          }
          .search-input::placeholder {
            color: rgba(255, 255, 255, 0.80);

            font-family: "Satoshi" ;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          .submit-icon,
          .x-icon {
            position: absolute;
            right: 20px;
            /* Adjust as needed */
            top: calc(50% + 3px);
            transform: translateY(-50%);
            // pointer-events: none;
            height: 23px;
            width: 18px;

            svg {
              cursor: pointer;
            }
          }
        }
      }

      .login {
        z-index: 999999999; /* Ensure the overlay is above other elements */
        display: flex;
        align-items: center;
        justify-content: space-between;
        // margin-left: auto;
        position: relative;
        right: 0;
        width: fit-content;
        white-space: nowrap;
        margin-left: auto;

        .vertical-line {
          width: 2px;
          height: 12px;
          border-radius: 46px;
          background: rgba(217, 217, 217, 0.22);
          margin: 0px 20px;
        }

        .mio-spazio-link {
          .mio-spazio {
            cursor: pointer;
            display: flex;
            height: 44px;
            // margin-right: 20px;

            .favs {
              cursor: pointer;

              color: #292d35;
              font-size: 16px;
              font-family: "Satoshi";
              font-weight: 700;
              border-radius: 10px;
              background: var(--white-90, rgba(255, 255, 255, 0.9));
              backdrop-filter: blur(22.5px);

              width: 44px;
              height: 100%;
              box-sizing: border-box;
              border-radius: 10px 0px 0px 10px;
              box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.13);
              /* Adjust the values as needed */
              display: flex;
              align-items: center;
              justify-content: center;
            }

            .image {
              width: 44px;
              height: 100%;
              background-image: url("https://images.unsplash.com/photo-1546838739-81394dda9a7b?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80");
              background-position: center center;
              background-repeat: no-repeat;
              background-size: cover;
              border-radius: 0px 10px 10px 0px;
              box-shadow: inset 0 0 0 2px rgba(255, 255, 255, 0.13);
              /* Adjust the values as needed */
            }
          }

        }

        .login-link{
          cursor: pointer;
          .login{
            display: flex;
            width: 44px;
            height: 44px;
            justify-content: center;
            align-items: center;

            border-radius: 10px;
            box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.10);
            // border: 2px solid rgba(0, 0, 0, 0.10);
            background-color: white;
            box-sizing: border-box;
            // margin-right: 15px;
          }
        }

        .hamburger {
          border-radius: 50%;
          width: 40px;
          height: 40px;
          position: relative;
          z-index: 3;

          border-radius: 10px;
          border: 2px solid rgba(255, 255, 255, 0.03);
          background: rgba(31, 34, 41, 0.6);
          backdrop-filter: blur(35px);
          display: flex;
          align-items: center;
          justify-content: center;
        }



      }
    }
  }
}

@media screen and (min-width: 1685px) {
  .navbar-container {
    .navbar {
      .search-bar-half {
        margin-left: 30%;
      }

      .links {
        .vertical-line {
          display: none;
        }
      }
    }
  }
}

/* navbar.scss */
/* Styles for the hamburger button */
.hamburger {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease-in-out;

  background: rgba(21, 24, 30, 0.60);
  backdrop-filter: blur(100px);

  div {
    width: 100%;
    height: 3px;
    background-color: white;
    transition: transform 0.3s ease-in-out;
  }
}

/* Styles for the open menu */



// .font-black class is used for next pages: chiedi al lab, autovaluazione, impostazioni
.font-black {
  color: black !important;

  .vertical-line {
    border-radius: 46px;
    background: rgba(217, 217, 217, 0.55) !important;
  }

  .navbar {
    .home {
      .logo {
        border: 2px solid rgba(49, 49, 49, 0.04) !important;
        background: rgba(252, 252, 252, 0.85) !important;

        svg {
          path {
            stroke: black;
          }
        }
      }

      .home-link {
        border: 2px solid rgba(49, 49, 49, 0.04) !important;
        background: rgba(252, 252, 252, 0.85) !important;
        border-left: 0px !important;
      }
    }

    .search-bar-half {
      color: black !important;

      .search-container {
        input {
          background: rgba(252, 252, 252, 0.85) !important;
          border: 2px solid rgba(49, 49, 49, 0.04) !important;
        }

        .search-input::placeholder {
          color: black;
        }
      }
    }

    .login {
      .mio-spazio-link {
        .favs {
          background-color: black !important;
        }
      }
    }

    .hamburger {
      border: 2px solid rgba(49, 49, 49, 0.04);

      background: rgba(252, 252, 252, 0.85) !important;

      svg {
        path {
          stroke: #0085ff;
        }
      }
    }
  }
}
